import React, { Component } from 'react';
import { captureException } from '@sentry/browser';

import './ErrorBoundary.scss';

const ERROR_MESSAGES = {
  400: 'Sorry, we cannot process that request right now.',
  403: 'Sorry, you\'re not authorized to view this page.',
  404: 'Sorry, we could not find the page you wanted.',
  500: 'Something has gone terribly wrong.',
  default: 'That\'s an error.',
};

/**
 * @type {React.Component}
 * @param {Function} onError
 * @description ErrorBoundary is used to wrap the application to catch error
 */
export default class ErrorBoundary extends Component {
  static defaultProps = {
    onError: () => { },
  }

  constructor (props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null,
    };

    this.throwError = this.throwError.bind(this);
  }

  componentDidCatch (error, info) {
    captureException(error, info);
    this.throwError(error, info);
  }

  componentWillUnmount () {
    this.unmounted = true;
  }

  throwError (error, info = null) {
    this.props.onError(error);
    !this.unmounted && this.setState({
      hasError: true,
      error: error,
      info: info,
    });
  }

  render () {
    const { error, hasError } = this.state;

    if (hasError && !Config.IS_PROD) {
      return <RenderError error={error} />;
    }

    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        throwError: this.throwError,
      });
    });
  }
}

export const RenderError = ({ error }) => {
  captureException(error);

  return (
    <div className="error-boundary container--padded container--flex-columns container--full-size">
      <span className="error-boundary__status">{error.status}</span>
      <span className="error-boundary__message">{error.message}</span>
      <p>{ERROR_MESSAGES[error.status] ? ERROR_MESSAGES[error.status] : ERROR_MESSAGES.default}</p>
      {error.status > 500 || !error.status ? (
        <div className="error-boundary__contact">
          This error has been logged and we are looking into it.<br/>
          Need help? Contact <a href="mailto:support@beatport.com">customer support</a>.
        </div>
      ) : null}
      {Config.STACKTRACE_ENABLED && (
        <pre className="error-boundary__stack-trace">
          <code>{error.stack}</code>
        </pre>
      )}
    </div>
  );
};
RenderError.defaultProps = {
  /**
   * @typedef {Error} error the error that will be displayed
   */
  error: Error(),
  /**
   * @typedef {Boolean} backButton should the component display a back button
   */
  backButton: false,
  /**
   * @typedef {Boolean} logError should the component log the error to Sentry
   */
  logError: false,
};
