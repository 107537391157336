import React from 'react';

export default () => (
  <svg width="32px" height="21px" viewBox="0 0 298 230" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>JCB_logo</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="-57.5270968%" y1="50.1241953%" x2="232.39121%" y2="50.1241953%" id="linearGradient-1">
        <stop stopColor="#007940" offset="0%"></stop>
        <stop stopColor="#00873F" offset="22.85%"></stop>
        <stop stopColor="#40A737" offset="74.33%"></stop>
        <stop stopColor="#5CB531" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="0.182516704%" y1="49.95997%" x2="100.273441%" y2="49.95997%" id="linearGradient-2">
        <stop stopColor="#007940" offset="0%"></stop>
        <stop stopColor="#00873F" offset="22.85%"></stop>
        <stop stopColor="#40A737" offset="74.33%"></stop>
        <stop stopColor="#5CB531" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="-62.8015845%" y1="49.8578253%" x2="253.671294%" y2="49.8578253%" id="linearGradient-3">
        <stop stopColor="#007940" offset="0%"></stop>
        <stop stopColor="#00873F" offset="22.85%"></stop>
        <stop stopColor="#40A737" offset="74.33%"></stop>
        <stop stopColor="#5CB531" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="0.175556793%" y1="50.0058048%" x2="101.808162%" y2="50.0058048%" id="linearGradient-4">
        <stop stopColor="#1F286F" offset="0%"></stop>
        <stop stopColor="#004E94" offset="47.51%"></stop>
        <stop stopColor="#0066B1" offset="82.61%"></stop>
        <stop stopColor="#006FBC" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="-0.575855512%" y1="49.9142191%" x2="98.13299%" y2="49.9142191%" id="linearGradient-5">
        <stop stopColor="#6C2C2F" offset="0%"></stop>
        <stop stopColor="#882730" offset="17.35%"></stop>
        <stop stopColor="#BE1833" offset="57.31%"></stop>
        <stop stopColor="#DC0436" offset="85.85%"></stop>
        <stop stopColor="#E60039" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="JCB_logo" fillRule="nonzero">
        <path d="M298.000001,183.2445 C298.000001,208.745723 277.23472,229.511004 251.733497,229.511004 L-3.99999749e-08,229.511004 L-3.99999749e-08,46.2665043 C-3.99999749e-08,20.7652817 20.7652812,4.3e-07 46.2665038,4.3e-07 L298,4.3e-07 L298.000001,183.2445 Z" id="path6325" fill="#FFFFFF"></path>
        <g id="g6327" transform="translate(199.638143, 15.118582)">
          <path d="M16.3936431,121.130807 L35.5195601,121.130807 C36.0660148,121.130807 37.341076,120.948656 37.8875307,120.948656 C41.5305625,120.220049 44.6271396,116.941321 44.6271396,112.387531 C44.6271396,108.015893 41.5305625,104.737164 37.8875307,103.826406 C37.341076,103.644255 36.2481664,103.644255 35.5195601,103.644255 L16.3936431,103.644255 L16.3936431,121.130807 Z" id="path6338" fill="url(#linearGradient-1)"></path>
          <path d="M33.333741,0.36430318 C15.118582,0.36430318 0.18215159,15.118582 0.18215159,33.5158926 L0.18215159,67.9425431 L46.9951102,67.9425431 C48.0880198,67.9425431 49.3630809,67.9425431 50.2738388,68.1246947 C60.8386311,68.6711494 68.6711494,74.1356971 68.6711494,83.6075798 C68.6711494,91.075795 63.3887533,97.4511006 53.5525675,98.7261618 L53.5525675,99.090465 C64.2995113,99.8190713 72.4963328,105.830074 72.4963328,115.119805 C72.4963328,125.138142 63.3887533,131.6956 51.3667484,131.6956 L2.58852978e-14,131.6956 L2.58852978e-14,199.091688 L48.6344745,199.091688 C66.8496335,199.091688 81.7860639,184.337409 81.7860639,165.940098 L81.7860639,0.36430318 L33.333741,0.36430318 Z" id="path6349" fill="url(#linearGradient-2)"></path>
          <path d="M42.2591689,85.7933989 C42.2591689,81.4217607 39.1625919,78.5073353 35.5195601,77.9608805 C35.1552569,77.9608805 34.2444989,77.7787289 33.6980442,77.7787289 L16.3936431,77.7787289 L16.3936431,93.8080688 L33.6980442,93.8080688 C34.2444989,93.8080688 35.3374085,93.8080688 35.5195601,93.6259173 C39.1625919,93.0794625 42.2591689,90.165037 42.2591689,85.7933989 L42.2591689,85.7933989 Z" id="path6360" fill="url(#linearGradient-3)"></path>
        </g>
        <path d="M49.727384,15.4828856 C31.512225,15.4828856 16.5757947,30.2371644 16.5757947,48.634475 L16.5757947,130.420539 C25.8655257,134.974329 35.51956,137.888754 45.1735943,137.888754 C56.6491445,137.888754 62.8422985,130.966994 62.8422985,121.495111 L62.8422985,82.8789739 L91.2579466,82.8789739 L91.2579466,121.312959 C91.2579466,136.24939 81.9682155,148.453546 50.4559904,148.453546 C31.3300734,148.453546 16.3936431,144.26406 16.3936431,144.26406 L16.3936431,214.028119 L65.0281176,214.028119 C83.2432766,214.028119 98.179707,199.27384 98.179707,180.876529 L98.179707,15.4828856 L49.727384,15.4828856 Z" id="path6371" fill="url(#linearGradient-4)"></path>
        <path d="M141.349634,15.4828856 C123.134475,15.4828856 108.198044,30.2371644 108.198044,48.634475 L108.198044,91.9865534 C116.577018,84.8826414 131.149145,80.3288516 154.6467,81.4217612 C167.21516,81.9682159 180.694377,85.4290961 180.694377,85.4290961 L180.694377,99.4547686 C173.954768,95.9938884 165.940098,92.8973113 155.557458,92.168705 C137.706602,90.8936438 126.959658,99.6369202 126.959658,114.937654 C126.959658,130.420539 137.706602,139.163815 155.557458,137.706602 C165.940098,136.977996 173.954768,133.699267 180.694377,130.420539 L180.694377,144.446211 C180.694377,144.446211 167.397311,147.907092 154.6467,148.453546 C131.149145,149.546456 116.577018,144.992666 108.198044,137.888754 L108.198044,214.392422 L156.832519,214.392422 C175.047678,214.392422 189.984108,199.638143 189.984108,181.240832 L189.984108,15.4828856 L141.349634,15.4828856 Z" id="path6384" fill="url(#linearGradient-5)"></path>
      </g>
    </g>
  </svg>
);
