import Plans from '@pages/plans/Plans';
import Welcome from '@pages/welcome/Welcome';
import Sorry from '@pages/sorry/Sorry';
import Enrolled from '@pages/enrolled/Enrolled';
import Checkout from '@pages/checkout/Checkout';
import Account from './pages/account/Account';
import Thanks from './pages/thanks/Thanks';

const ROUTES = {
  welcome: {
    path: Config.ROUTE_INDEX,
    component: Welcome,
    title: 'Hype',
  },
  plans: {
    path: Config.ROUTE_PLANS,
    component: Plans,
    title: 'Hype Subscription Plans',
  },
  sorry: {
    path: Config.ROUTE_SORRY,
    component: Sorry,
    title: 'Sorry'
  },
  enrolled: {
    path: Config.ROUTE_ENROLLED,
    component: Enrolled,
    title: 'Already Enrolled'
  },
  checkout: {
    path: Config.ROUTE_CHECKOUT,
    component: Checkout,
    title: 'Checkout'
  },
  account: {
    path: Config.ROUTE_ACCOUNT,
    component: Account,
    title: 'My Account'
  },
  thanks: {
    path: Config.ROUTE_THANKS,
    component: Thanks,
    title: 'Thank You'
  }
};

export default ROUTES;
