// PACKAGES
import React, { Fragment } from 'react';
// ASSETS
import AmEx from '@assets/payment/AmEx';
import JCB from '@assets/payment/JCB';
import MasterCard from '@assets/payment/Mastercard';
import PayPal from '@assets/payment/PayPal';
import Visa from '@assets/payment/Visa';
// STYLES
import './PaymentMethods.scss';

const paymentMethods = {
  CARD: 'card',
  PAYPAL: 'paypal'
};

export const defaultPaymentMethod = (billingInfo) => {
  return (billingInfo && billingInfo.type === 'paypal')
    ? paymentMethods.PAYPAL
    : paymentMethods.CARD;
};

/**
 * @type {SFC}
 * @param {Boolean} isEditable
 * @param {String} paymentMethod
 * @param {Function} setPaymentMethod
 * @description The two payment methods
 */
const PaymentMethods = ({ isEditable, padded, paymentMethod, setPaymentMethod }) => {
  return (
    <div className={`PaymentMethods ${padded ? 'PaymentMethods-padded' : ''}`}>
      {Object.values(paymentMethods).map(pm => (
        <div
          className={`PaymentMethods__method ${pm === paymentMethod ? 'active' : ''} ${isEditable ? 'editable' : ''}`}
          key={pm}
          onClick={() => { setPaymentMethod(pm); }}
        >
          {pm === paymentMethods.CARD ? (
            <Fragment>
              <Visa />
              <JCB />
              <AmEx />
              <MasterCard />
            </Fragment>
          ) : (
            <PayPal />
          )}
        </div>
      ))}
    </div>
  );
};

export default PaymentMethods;
