const { storage } = require('local-storage-fallback');

const isSupported = (ls) => {
  try {
    const key = '__test__';
    ls.setItem(key, key);
    ls.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
};

if (!isSupported(window.localStorage)) {
  window.localStorage = storage;
}
