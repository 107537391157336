// PACKAGES
import React, { Component, createRef } from 'react';
import { configureScope } from '@sentry/browser';
// UI
import { Authenticator } from '@beatport/ui/Authenticator';
import { Button } from '@beatport/ui/Buttons';
import { SearchAutoComplete } from '@beatport/ui/SearchAutoComplete';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
// STATIC
import defaultImage from '../../images/default.jpg';
// STYLES
import './Welcome.scss';

/**
 * @type {React.Component}
 * @param {Object} bpuser
 * @param {Function} clearLocalStorage
 * @param {Object} label
 * @param {Boolean} loading
 * @param {Function} setBillingInfoState
 * @param {Function} setLabelState
 * @param {Function} setUserState
 * @description Index route for logging in and selecting a label to enroll in Hype
 */
export default class Welcome extends Component {
  state = {
    /**
     * @type {Boolean} the current state determining if the input is valid
     */
    isValid: false,
    /**
     * @type {String} inputsearch autocomplete input display
     */
    display: '',
  }

  labelInput = createRef();

  handleLabelSelect = (query, label) => {
    this.props.setLabelState(label);
    this.setState({ display: label && label.name });
  }

  handleLoginSuccess = (bpuser) => {
    const { setBillingInfoState, setUserState } = this.props;
    configureScope((scope) => scope.setUser({ id: bpuser.person_id, username: bpuser.username }));
    setUserState(bpuser);
    setBillingInfoState();
    this.labelInput && this.labelInput.focus();
  }

  handleLogoutSuccess = () => {
    this.props.clearLocalStorage();
    this.setState({ display: '' });
  }

  handleValidate = (isValid) => this.setState({ isValid })

  fetchLabels = async (query) => {
    if (!query) {
      return Promise.resolve([]);
    }
    try {
      const params = { autcomplete: true, per_page: 20, q: query, type: 'labels' };
      const { request } = BeatportAPI.search.get({ params });
      const response = await request;
      return response.data.labels;
    } catch (err) {
      this.props.throwError(err);
      return [];
    }
  }

  renderResults = ({ results, selectedIndex, onSelect }) => {
    return (
      <div className="">
        {results.map((item, index) => {
          const classList = selectedIndex === index
            ? 'Welcome__list-item Welcome__list-item--active'
            : 'Welcome__list-item';

          return (
            <li className={classList} key={item.id} onMouseDown={(e) => onSelect(e, item)}>
              <img
                className="Welcome__list-image"
                src={item.image ? item.image.uri : defaultImage}
              />
              <span className="Welcome__list-text">{item.name}</span>
            </li>
          );
        })}
      </div>
    );
  }

  nextRoute = () => {
    const { label } = this.props;
    switch (true) {
      case !label.hype_eligible || !label.is_available_for_hype:
        return Config.ROUTE_SORRY;
      case label.hype_active:
        return Config.ROUTE_ENROLLED;
      default:
        return Config.ROUTE_PLANS;
    }
  };

  render () {
    const { label, bpuser, history } = this.props;
    const isValid = bpuser && this.state.isValid;
    return (
      <div className="Welcome">
        <div className="Welcome__intro">
          <h2>Subscribe or login to Beatport Hype</h2>
          <h3>Login first with your Beatport account and then select your label</h3>
        </div>
        <div className="Welcome__row">
          <div className="Welcome__column">
            <h3>Step 1</h3>
          </div>
          <div className="Welcome__column">
            <Authenticator
              authorizeUri={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_AUTHORIZE}`}
              clientId={Config.API_CLIENT_ID}
              introspectUri={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_INTROSPECT}`}
              logoutUri={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_LOGOUT}`}
              onError={this.props.throwError}
              onSuccess={this.handleLoginSuccess}
              onLogoutSuccess={this.handleLogoutSuccess}
              redirectUri={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_REDIRECT}?origin=${window.location.href}`}
              revokeUri={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_REVOKE}`}
              tokenUri={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_TOKEN}`}
            />
            {!bpuser && (
              <span className="register-account">
                Don&#39;t have an account yet?&nbsp;
                <a href={`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_REGISTER_URL}?next=${window.location.origin}`}>
                   Create One!
                </a>
              </span>
            )}
          </div>
        </div>
        <div className="Welcome__row">
          <div className="Welcome__column">
            <h3>Step 2</h3>
          </div>
          <div className="Welcome__column">
            <SearchAutoComplete
              animateLabel
              className="Welcome__label-input"
              disabled={!bpuser}
              fetchFn={this.fetchLabels}
              inputRef={(r) => { this.labelInput = r; }}
              label="Find your label"
              onSubmit={this.handleLabelSelect}
              onValidate={this.handleValidate}
              onChange={value => this.setState({ display: value })}
              renderResults={this.renderResults}
              typingInterval={200}
              value={this.state.display}
              validation={['required']}
            />
          </div>
        </div>
        <div className="container--flex-center">
          <Button
            category="hype"
            disabled={!isValid || !label}
            onClick={() => history.push({ pathname: this.nextRoute() })}
            size="large"
          >
            Continue
          </Button>
        </div>
        <div className="container--flex-center Support">
          <p>
            <a href="https://labels.beatport.com/hype" rel="noopener noreferrer" target="_blank">
              Get more info about Hype
            </a>
          </p>
          <p>Need help? Contact
            <a
              href="http://labelsupport.beatport.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;customer support
            </a>
          </p>
        </div>
      </div>
    );
  }
}
