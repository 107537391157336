import { cancelableFetch } from '@lib/cancelableFetch';

const {
  API_HOST,
  API_PROTOCOL,
  API_ENDPOINT_BILLING_INFO,
  API_ENDPOINT_PLANS,
  API_ENDPOINT_SUBSCRIPTIONS,
  API_ENDPOINT_USERS,
} = Config;
const BASE_URL = `${API_PROTOCOL}${API_HOST}`;

const endpointConfig = {
  billingInfo: {
    methods: ['get', 'put'],
    url: `${BASE_URL}${API_ENDPOINT_BILLING_INFO}`
  },
  cancelSubscription: {
    methods: ['put'],
    url: `${BASE_URL}${API_ENDPOINT_SUBSCRIPTIONS}:id/cancel/`
  },
  reactivateSubscription: {
    methods: ['put'],
    url: `${BASE_URL}${API_ENDPOINT_SUBSCRIPTIONS}:id/reactivate/`
  },
  plans: {
    methods: ['get'],
    url: `${BASE_URL}${API_ENDPOINT_PLANS}`,
  },
  search: {
    methods: ['get'],
    url: `${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SEARCH}`
  },
  subscriptions: {
    methods: ['get', 'post'],
    url: `${BASE_URL}${API_ENDPOINT_SUBSCRIPTIONS}`
  },
  user: {
    methods: ['get'],
    url: `${BASE_URL}${API_ENDPOINT_USERS}:id/`
  }
};

/**
 *
 * @param {String} url
 * @param {String} method
 * @description Creates a function that makes a request against a given url with the given method.
 * If the request url requires parameters, the returned function will take a param object and an
 * optional config object.
 */
export const createIntegrationMethod = (url, method) => {
  if (/[\/\/].+:/.test(url)) { // eslint-disable-line
    return (params = {}, config) => {
      const urlWithParams = Object.entries(params)
        .reduce((u, entry) => u.replace(`:${entry[0]}`, entry[1]), url);

      return cancelableFetch(urlWithParams, method, config);
    };
  }

  return (config) => cancelableFetch(url, method, config);
};

/**
 * @description this function creates the integration endpoints for the BeatportAPI
 * based on the endpoint config
 */
const BeatportAPI = Object.entries(endpointConfig).reduce((accumulator, entry) => {
  const [key, integration] = entry;

  accumulator[key] = integration.methods.reduce((acc, method) => {
    acc[method] = createIntegrationMethod(integration.url, method);
    return acc;
  }, {});

  return accumulator;
}, {});

export default BeatportAPI;
