// PACKAGES
import '@babel/polyfill';
import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// CONFIG
import ROUTES from '@routes';
// ROUTES
import Welcome from './pages/welcome/Welcome';
// COMPONENTS
import Header from '@components/Header/Header';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
import { captureException } from '@sentry/browser';
// STYLES
import './App.scss';

export default class App extends Component {
  state = {
    /**
     * @type {null|Object} the billing info object for the logged in user
     */
    billingInfo: null,
    /**
     * @type {null|Object} the introspect object for the logged in user
     */
    bpuser: null,
    /**
     * @type {null|Object} the currently selected label
     */
    label: null,
    /**
     * @type {Boolean} whether the app has finished initing
     */
    loading: true,
    /**
     * @type {null|Object} the currently selected plan
     */
    plan: null,
  }

  componentDidMount () {
    const bpuser = window.localStorage.getItem('bpuser')
      ? JSON.parse(window.localStorage.getItem('bpuser'))
      : null;
    const label = window.localStorage.getItem('label')
      ? JSON.parse(window.localStorage.getItem('label'))
      : null;
    const plan = window.localStorage.getItem('plan')
      ? JSON.parse(window.localStorage.getItem('plan'))
      : null;
    this.setState({ bpuser, label, plan, loading: false });
    bpuser && this.setBillingInfoState();
  }

  componentWillUnmount () {
    this.clearLocalStorage();
  }

  clearLocalStorage = () => {
    ['billingInfo', 'label', 'plan', 'bpuser'].forEach(key => {
      window.localStorage.removeItem(key);
      this.setState({ [key]: null });
    });
  }

  setBillingInfoState = async (billingInfo) => {
    if (billingInfo) {
      this.setState({ billingInfo });
      window.localStorage.setItem('billingInfo', JSON.stringify(billingInfo));
      return;
    }
    try {
      const { request } = BeatportAPI.billingInfo.get();
      const response = await request;
      if (typeof response.data.first_name !== 'undefined') {
        this.setState({ billingInfo: response.data });
        window.localStorage.setItem('billingInfo', JSON.stringify(response.data));
      }
    } catch (err) {
      !window.Config.IS_PROD && console.warn(err);
      captureException(err);
    }
  }

  setLabelState = (label) => {
    label
      ? window.localStorage.setItem('label', JSON.stringify(label))
      : window.localStorage.removeItem('label');
    this.setState({ label });
  }

  setPlanState = (plan) => {
    plan
      ? window.localStorage.setItem('plan', JSON.stringify(plan))
      : window.localStorage.removeItem('plan');
    this.setState({ plan });
  }

  setUserState = (bpuser) => {
    bpuser
      ? window.localStorage.setItem('bpuser', JSON.stringify(bpuser))
      : window.localStorage.removeItem('bpuser');
    this.setState({ bpuser });
  }

  routeProps = (route) => {
    const { clearLocalStorage, setBillingInfoState, setLabelState, setPlanState, setUserState } = this;
    const { billingInfo, bpuser, label, plan } = this.state;

    switch (route) {
      case Config.ROUTE_INDEX:
        return {
          bpuser,
          clearLocalStorage,
          label,
          setBillingInfoState,
          setLabelState,
          setUserState
        };
      case Config.ROUTE_CHECKOUT:
        return {
          billingInfo,
          bpuser,
          label,
          plan,
          setBillingInfoState
        };
      case Config.ROUTE_ACCOUNT:
        return { billingInfo, plan, setBillingInfoState };
      case Config.ROUTE_PLANS:
        return { billingInfo, plan, setPlanState };
      case Config.ROUTE_THANKS:
        return { billingInfo, label, plan };
      case Config.ROUTE_ENROLLED:
      case Config.ROUTE_SORRY:
        return { label };
      default:
        return {};
    }
  }

  render () {
    const { bpuser, loading } = this.state;
    return (
      <div className="App">
        <Router>
          <Switch location={location}>
            <Header isLoggedIn={bpuser} />
          </Switch>
          <Switch location={location}>
            {((!bpuser && !loading) && location.pathname !== Config.ROUTE_INDEX)
              ? <Route
                exact
                key={location.pathname}
                path={Config.ROUTE_INDEX}
                render={props => (
                  <div className="App__page-wrapper">
                    <ErrorBoundary>
                      <Welcome {...props} {...this.routeProps(Config.ROUTE_INDEX)} />
                    </ErrorBoundary>
                  </div>
                )}
              />
              : Object.keys(ROUTES).map((route) => {
                const { path, component } = ROUTES[route];
                const Page = component;
                return (
                  <Route
                    exact
                    key={location.pathname}
                    path={path}
                    render={props => (
                      <div className="App__page-wrapper">
                        <ErrorBoundary>
                          <Page {...props} {...this.routeProps(path)} />
                        </ErrorBoundary>
                      </div>
                    )}
                  />
                );
              })}
            <Redirect to={Config.ROUTE_INDEX} />
          </Switch>
        </Router>
      </div>
    );
  }
}
