import React, { Component } from 'react';
import { Button } from '@beatport/ui/Buttons';
import { IconCheck } from '@beatport/ui/Icons';
import { HypeLogo } from '@components/HypeLogo/HypeLogo';
// CONFIG
import { currencyMap } from '../RecurlyForm/RecurlyConfig';
// STYLES
import './PlanModule.scss';

/**
 * @type {React.Component}
 * @param {Function} onClick
 * @param {Object} Plan
 * @description Component rendered for each Hype plan available via Recurly
 */
export default class PlanModule extends Component {
  constructor (props) {
    super(props);
    this.state = {
      /**
       * @type {Boolean} controls dynamic button category style props
       */
      buttonHovered: false
    };
  }

  planPrice = () => {
    const { plan_price } = this.props.plan;
    let number = plan_price.USD;
    let code = 'USD';

    if (this.props.billingInfo) {
      const { country } = this.props.billingInfo;
      code = currencyMap[country] || code;
      number = plan_price[code] || number;
    }

    const numberJSX = number.split(/(\d+)/).map((n, i) => <span key={i}>{n}</span>);

    return (
      <div className="PlanModule__price">
        <div className="PlanModule__price__number">{ numberJSX }</div>
      </div>
    );
  }

  render () {
    const { onClick } = this.props;
    return (
      <div className="PlanModule">
        <div className="PlanModule__upper">
          <HypeLogo />
          <div className="PlanModule__heading">
            { this.planPrice() }
            <div className="PlanModule__heading__copy">
              Plus applicable taxes. Billed monthly.
            </div>
          </div>
          <Button
            category={this.state.buttonHovered ? 'hype' : 'outlined'}
            style={{ backgroundColor: this.state.buttonHovered ? '#00bfff' : '' }}
            noMargin
            onClick={onClick}
            onMouseEnter={() => { this.setState({ buttonHovered: true }); }}
            onMouseLeave={() => { this.setState({ buttonHovered: false }); }}
            size="medium"
          >
            Select Plan
          </Button>
        </div>
        <div className="PlanModule__lower">
          <ul className="PlanModule__feature-list">
            <li className="PlanModule__feature">
              <IconCheck className="PlanModule__feature-checkmark" />
              <div className="PlanModule__feature-copy">
                Average increased sales of over 40%
              </div>
            </li>
            <li className="PlanModule__feature">
              <IconCheck className="PlanModule__feature-checkmark" />
              <div className="PlanModule__feature-copy">
                Dedicated Hype Charts
              </div>
            </li>
            <li className="PlanModule__feature">
              <IconCheck className="PlanModule__feature-checkmark" />
              <div className="PlanModule__feature-copy">
                Opportunity to be featured in high-visibility contexts
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
