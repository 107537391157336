import axios from 'axios';

/**
 * A method wrapping an axios request that returns both the request and a cancel method
 * @param {string} url The url to make the request against.
 * @param {string} method The REST method in lowercase to make against the url, defaults to 'get'.
 * @param {object} config Additional config options to pass to the axios request, i.e. 'params' for a 'get' or 'data' for a 'post'. Documented at https://github.com/axios/axios.
 * @returns {object} An object with the structure of `{ request, cancel }`, with 'request' being the request promise and cancel being the cancel method for the request.
 */
export const cancelableFetch = (url, method = 'get', config = {}) => {
  const source = axios.CancelToken.source();
  const user = window.localStorage.getItem('bpuser')
    ? JSON.parse(window.localStorage.getItem('bpuser'))
    : { access_token: null };
  const API_ACCESS_TOKEN = user.access_token;
  axios.defaults.headers.common['Authorization'] = `Bearer ${API_ACCESS_TOKEN}`;
  const request = axios[method](url, {
    cancelToken: source.token,
    ...config
  });

  return {
    request,
    cancel: (message = '') => {
      source.cancel(`Cancelled ${method} to ${url}: ${message}`);
    }
  };
};

/**
 * When a request is cancelled, the cancel is raised as an error, but usually that error isn't something that should be acted on.
 * This method creates a 'catch' function that ignores errors thrown as the result of a cancel.
 * Axios's catching is slightly non-standard in that all catches in a chain are called on error, so for the time being using
 * methods created by catchCancel instead of filtering cancel errors in a catch higher up the chain is the chosen approach.
 * @param {*} fn The function to call if the error wasn't generated by a cancel
 */
export const catchCancel = (fn) => (e) => {
  if (!e.response && axios.isCancel(e)) {
    console.warn(e.message);
    return;
  }

  fn(e);
};
