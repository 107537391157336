// PACKAGES
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { LabelBaseLogo } from '@components/LabelBaseLogo/LabelBaseLogo';
import { IconUser2 } from '@beatport/ui/Icons';
// STYLES
import './Header.scss';

/**
 * @type {SFC}
 * @param {Boolean} isLoggedIn
 * @description Header present in every view of the app
 */
const Header = ({ isLoggedIn }) => (
  <header className="Header">
    <a href="https://labels.beatport.com" className="Header__back">
      ← <strong>Label</strong>Base
    </a>
    <Link to={{ pathname: '/' }}>
      <LabelBaseLogo />
    </Link>
    {isLoggedIn && (
      <Link className="Header__account-link" to={{ pathname: '/account' }}>
        <IconUser2 className="Header__account link-icon" />
        <span>Account</span>
      </Link>
    )}
  </header>
);

export default Header;
