// PACKAGES
import React from 'react';
// COMPONENTS
import { HypeLogo } from '@components/HypeLogo/HypeLogo';
// STYLES
import './Sorry.scss';

/**
 * @type {SFC}
 * @param {Object} label
 * @description Route displayed when selected label is not Hype-eligible
 */
export default ({ label }) => {
  const labelName = label ? label.name : 'Your label';
  return (
    <div className="Sorry">
      <HypeLogo className="Sorry__logo" />
      <div className="Sorry__message">
        <strong>{labelName}</strong>  is not eligible for Hype,
        for one or more of the following reasons:
      </div>
      <div className="Sorry__message-reason Sorry__message-reason__inline">
        <strong>{labelName}&#39;s&nbsp;</strong> sales over the last 12 months exceed the Hype sales threshold.
      </div>
      <hr />
      <div className="Sorry__message-reason">
      It is a compilation label.
      </div>
      <hr />
      <div className="Sorry__message">
        If you feel none of the above applies to <strong>{labelName}</strong>,
        please email Beatport&#39;s Label Management team at
        <a href="mailto:labelsupport@beatport.com"> labelsupport@beatport.com </a>
        to discuss eligibility.
      </div>
    </div>
  );
};
