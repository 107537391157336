import React from 'react';

export default () => (
  <svg width="32px" height="21px" viewBox="0 0 32 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>visa</title>
    <desc>Created with Sketch.</desc>
    <g id="Onboarding-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Checkout---PayPal" transform="translate(-479.000000, -321.000000)" fillRule="nonzero">
        <g id="Tabs" transform="translate(455.000000, 307.000000)">
          <g id="CC-&amp;-Debit-Tab">
            <g id="visa" transform="translate(24.000000, 14.000000)">
              <path d="M1.64102564,0 L30.3589744,0 C31.2653128,0 32,0.752178 32,1.68 L32,19.32 C32,20.247864 31.2653128,21 30.3589744,21 L1.64102564,21 C0.734728205,21 0,20.247864 0,19.32 L0,1.68 C0,0.752178 0.734728205,0 1.64102564,0 Z" id="Path" fill="#0E4595"></path>
              <path d="M12.0287179,14.64666 L13.3973744,6.42474 L15.5865026,6.42474 L14.2168615,14.64666 L12.0287179,14.64666 Z M22.1255385,6.60198 C21.6918974,6.435408 21.0122256,6.256656 20.1636103,6.256656 C18.0005333,6.256656 16.476841,7.371756 16.4639179,8.969982 C16.4516513,10.151442 17.5516718,10.810506 18.3819487,11.203794 C19.2340923,11.606784 19.5204923,11.863782 19.5164718,12.22368 C19.5110974,12.774762 18.8360205,13.026552 18.2068513,13.026552 C17.3306667,13.026552 16.8651897,12.901938 16.1462564,12.59496 L15.8641641,12.46434 L15.5569231,14.304906 C16.0682256,14.534394 17.0137436,14.733222 17.9954051,14.743512 C20.2965333,14.743512 21.7902769,13.64118 21.8073436,11.934468 C21.8155077,10.99917 21.2323282,10.287396 19.9693538,9.700572 C19.2042256,9.320262 18.7356308,9.066414 18.7405949,8.681316 C18.7405949,8.339562 19.1372308,7.974078 19.9942154,7.974078 C20.7100718,7.962738 21.2286769,8.122548 21.6326974,8.289078 L21.828841,8.383998 L22.1255385,6.60198 L22.1255385,6.60198 Z M27.7587692,6.424614 L26.0672,6.424614 C25.5431795,6.424614 25.1510154,6.571068 24.9209026,7.106442 L21.6698667,14.641242 L23.9685744,14.641242 C23.9685744,14.641242 24.3443692,13.628076 24.4293744,13.405686 C24.6806564,13.405686 26.9136821,13.409214 27.2329846,13.409214 C27.2984615,13.69704 27.499241,14.641242 27.499241,14.641242 L29.5305846,14.641242 L27.7587692,6.424362 L27.7587692,6.424614 Z M25.0749538,11.733834 C25.255959,11.260116 25.947159,9.435468 25.947159,9.435468 C25.9341949,9.457392 26.1268103,8.95944 26.2373744,8.65074 L26.3852718,9.359658 C26.3852718,9.359658 26.8045128,11.322276 26.8921026,11.733834 L25.0749538,11.733834 Z M10.1703385,6.424614 L8.02715897,12.031614 L7.79876923,10.892154 C7.39979487,9.578688 6.15671795,8.155644 4.76705641,7.443198 L6.72668718,14.633598 L9.04283077,14.63091 L12.4891487,6.42453 L10.1703385,6.42453 L10.1703385,6.424614 Z" id="Shape" fill="#FFFFFF"></path>
              <path d="M6.02748718,6.42432 L2.4976,6.42432 L2.46966154,6.595386 C5.2158359,7.275954 7.03294359,8.920632 7.78740513,10.896606 L7.01981538,7.118286 C6.88730256,6.597696 6.50301538,6.442338 6.02756923,6.424152" id="Path" fill="#F2AE14"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
