// PACKAGES
import React, { Component } from 'react';
import { IconCheck } from '@beatport/ui/Icons';
import { Button } from '@beatport/ui/Buttons';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
// UTILS
import { currencyMap } from '@components/RecurlyForm/RecurlyConfig';
import { prettyDate } from '@lib/utils';
// STYLES
import './Thanks.scss';

/**
 * @type {React.Component}
 * @param {Object} label
 * @param {Object} plan
 * @description Route displayed upon successful checkout
 */
export default class Thanks extends Component {
  constructor (props) {
    super(props);
    this.state = {
      /**
       * @type {Object} billingInfo for user
       */
      billingInfo: props.billingInfo,
      /**
       * @type {Boolean} current loading state
       */
      loading: true,
      /**
       * @type {Object} specific subscription just purchased
       */
      subscription: null,
    };
  }

  async componentDidMount () {
    await this.fetchSubscriptions();
    !this.props.billingInfo && await this.fetchBillingInfo();
    this.setState({ loading: false });
  }

  fetchSubscriptions = async () => {
    try {
      const { request } = BeatportAPI.subscriptions.get();
      const response = await request;
      const subscriptions = response.data.results;
      const subscription = subscriptions.filter(sub => sub.label.id === this.props.label.id)[0];
      this.setState({ subscription });
    } catch (err) {
      this.props.throwError(err);
    }
  }

  fetchBillingInfo = async () => {
    try {
      const { request } = BeatportAPI.billingInfo.get();
      const response = await request;
      const billingInfo = response.data;
      this.setState({ billingInfo });
    } catch (err) {
      this.props.throwError(err);
    }
  }

  render () {
    const { loading, subscription } = this.state;
    const planPrice = this.props.plan ? this.props.plan.plan_price : { USD: '$9.99' };
    const startDate = subscription && subscription.start_date
      ? `this date: ${prettyDate(subscription.start_date)}.`
      : 'today.';
    const code = this.state.billingInfo ? currencyMap[this.state.billingInfo.country] : 'USD';
    const number = planPrice[code] || planPrice.USD;

    return loading ? null : (
      <div className="Thanks">
        <h1 className="Thanks__heading">
          Welcome to Beatport Hype!
        </h1>
        <div className="Thanks__message">
          Your tracks will shortly be eligible to enter the Hype Top 100 Charts, and be featured by curation in Hype features
        </div>
        <ul>
          <li>
            <IconCheck className="Thanks__icon" />
            <div>
                You will be charged {number}{code} (and applicable tax) monthly from {startDate}
            </div>
          </li>
          <li>
            <IconCheck className="Thanks__icon" />
            <div>
                You can manage your subscription from your account.
            </div>
          </li>
          <li>
            <IconCheck className="Thanks__icon" />
            <div>
                You can cancel your subscription at any time.
            </div>
          </li>
        </ul>
        <a href="https://labels.beatport.com">
          <Button category="outlined" className="Thanks__btn" withPadding>Back to LabelBase</Button>
        </a>
      </div>
    );
  }
}
