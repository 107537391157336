// PACKAGES
import React, { Component } from 'react';
// COMPONENTS
import PaymentMethods, { defaultPaymentMethod } from '@components/PaymentMethods/PaymentMethods';
import RecurlyForm from '@components/RecurlyForm/RecurlyForm';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
// STYLES
import './Checkout.scss';

/**
 * @type {React.Component}
 * @param {Object} billingInfo
 * @param {Object} bpuser
 * @param {Object} label
 * @param {Function} setBillingInfoState
 * @param {Function} setLabelState
 * @description Route for checking out with Recurly Form
 */
export default class Checkout extends Component {
  constructor (props) {
    super(props);
    this.state = {
      /**
       * @type {Boolean} whether component has mounted
       */
      inited: false,
      /**
       * @type {Boolean} whether you can type into form, initially based on billing info props
       */
      isEditable: !this.props.billingInfo,
      /**
       * @type {Boolean} current loading state
       */
      loading: false,
      /**
       * @type {String} either `paypal` or `card` - determines how Recurly API interfaced with
       */
      paymentMethod: defaultPaymentMethod(props.billingInfo),
      /**
       * @type {Boolean} whether there is an error with submitting the form
       */
      submitError: false,
    };
  }

  componentDidMount () {
    this.setState({ inited: true });
  }

  componentDidUpdate (prevProps) {
    (this.props.billingInfo && prevProps.billingInfo) &&
      (this.props.billingInfo.type !== prevProps.billingInfo.type) &&
      this.setState({ paymentMethod: defaultPaymentMethod(this.props.billingInfo) });
  }

  handleFormSubmit = (token, optInNewsletter, currency) => {
    if (this.props.billingInfo && this.state.isEditable) {
      this.updateBillingInfo(token);
    } else {
      this.postCheckoutToAPI(token, optInNewsletter, currency);
    }
  }

  setSubmitErrorState = (submitError) => { this.setState({ submitError }); }

  setEditable = () => this.setState({ isEditable: !this.state.isEditable })

  postCheckoutToAPI = async (token, optIn = false, currency = 'USD') => {
    const { history, label, plan, throwError } = this.props;
    this.setState({ loading: true });
    try {
      const payload = {
        billing_token: token ? token.id : '',
        currency,
        opt_in_hype_newsletter: optIn,
        plan_code: plan ? plan.plan_code : '',
        record_label_id: label ? label.id : '',
      };
      const { request } = BeatportAPI.subscriptions.post(payload);
      await request;
      history.push({ pathname: '/thanks' });
    } catch (err) {
      throwError(err);
      this.setSubmitErrorState(true);
    } finally {
      this.setState({ loading: false });
    }
  }

  updateBillingInfo = async (token) => {
    this.setState({ loading: true });
    try {
      const payload = { billing_token: token ? token.id : '' };
      const { request } = BeatportAPI.billingInfo.put(payload);
      await request;
      this.props.setBillingInfoState();
      this.postCheckoutToAPI(token);
    } catch (err) {
      this.props.throwError(err);
      this.setSubmitErrorState(true);
      this.setState({ loading: false });
    }
  }

  resetPaymentMethod = () => this.setState({ paymentMethod: defaultPaymentMethod(this.props.billingInfo) });

  render () {
    const { inited, isEditable, loading, paymentMethod, submitError } = this.state;
    return (!inited && loading) ? null : (
      <div className="Checkout">
        <h1 className="Checkout__heading">Get Beatport Hype</h1>
        <div className="Checkout__payment-methods">
          <PaymentMethods
            isEditable={isEditable}
            paymentMethod={paymentMethod}
            setPaymentMethod={(pm) => { this.setState({ paymentMethod: pm, isEditable: true }); }}
          />
        </div>
        <RecurlyForm
          billingInfo={this.props.billingInfo}
          isCheckout
          isEditable={isEditable}
          loading={loading}
          onFormSubmit={this.handleFormSubmit}
          paymentMethod={paymentMethod}
          planPrice={this.props.plan ? this.props.plan.plan_price : { USD: '$9.99' }}
          resetPaymentMethod={this.resetPaymentMethod}
          setEditable={this.setEditable}
          setSubmitErrorState={this.setSubmitErrorState}
          submitError={submitError}
          throwError={this.props.throwError}
        />
        <div className="Checkout__asterisk">*Plus applicable taxes. Billed monthly.</div>
      </div>
    );
  }
}
