// PACKAGES
import React, { Component } from 'react';
// COMPONENTS
import PlanModule from '@components/PlanModule/PlanModule';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
// STYLES
import './Plans.scss';

/**
 * @type {React.Component}
 * @param {Object} billingInfo
 * @param {Object} plan
 * @param {Function} setPlanState
 * @description Route for selecting Hype plan to purchase
 */
export default class Plans extends Component {
  state = {
    /**
     * @type {Boolean} current loading state
     */
    loading: true,
    /**
     * @type {Array} current list of offers via recurly API
     */
    recurlyPlans: []
  }

  componentDidMount () {
    this.fetchRecurlyPlans();
  }

  handlePlanSelect = plan => () => {
    const { history, setPlanState } = this.props;
    setPlanState(plan);
    history.push({ pathname: Config.ROUTE_CHECKOUT });
  }

  fetchRecurlyPlans = async () => {
    try {
      const { request } = BeatportAPI.plans.get();
      const response = await request;
      const recurlyPlans = response.data.filter(plan => plan.plan_code.indexOf('hype') !== -1);
      this.setState({ recurlyPlans });
    } catch (err) {
      this.props.throwError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  renderPlanModules = () => this.state.recurlyPlans.map((plan, i) => {
    return (
      <PlanModule key={i} billingInfo={this.props.billingInfo} plan={plan} onClick={this.handlePlanSelect(plan)} />
    );
  })

  render () {
    const { loading, recurlyPlans } = this.state;
    return loading ? null : (
      <div className="Plans">
        <div className="Plans__content">
          <h1 className="Plans__content__title">Select a plan</h1>
          <h3 className="Plans__content__subhead">Take your label to the next level.</h3>
          <div className="Plans__content__list">
            {recurlyPlans && this.renderPlanModules()}
          </div>
        </div>
      </div>
    );
  }
}
