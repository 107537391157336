// PACKAGES
import React, { Component, createRef, Fragment } from 'react';
// COMPONENTS
import { Button } from '@beatport/ui/Buttons';
import { Checkbox } from '@beatport/ui/Checkbox';
import { IconCheck, IconError } from '@beatport/ui/Icons';
import { Modal } from '@beatport/ui/Modal';
// CONFIG
import {
  cardFieldNames,
  countryCodes,
  currencyMap,
  errorFieldNames,
  inputNames,
  needsState,
  recurlyConfig,
  stateCodes
} from './RecurlyConfig';
// STYLES
import './RecurlyForm.scss';

const allInputFields = { ...inputNames, ...cardFieldNames };

const initialInputState = (props) => Object.values(allInputFields).reduce((obj, val) => {
  obj[val] = {
    /**
     * @type {Boolean} whether input has error
     */
    error: false,
    /**
     * @type {Boolean} whether input has been validated
     */
    isValidated: false,
    /**
     * @type {String} input's sibling's error message
     */
    message: '',
    /**
     * @type {Boolean} whether input should render
     */
    renders: !(val === 'state' && props.billingInfo && needsState.indexOf(props.billingInfo.country) === -1),
    /**
     * @type {Boolean} whether input is required
     */
    required: val !== 'address2',
    /**
     * @type {String} value of controlled input
     */
    value: val === 'country' ? props.billingInfo ? props.billingInfo.country : 'US' : '',
  };
  return obj;
}, {});

/**
 * @type {React.Component}
 * @param {Object} billingInfo
 * @param {Boolean} isCheckout
 * @param {Boolean} isEditable
 * @param {Boolean} loading
 * @param {Function} onFormSubmit
 * @param {String} paymentMethod
 * @param {String} planPrice
 * @param {Function} resetPaymentMethod
 * @param {Function} setEditable
 * @param {Function} setSubmitErrorState
 * @param {Function} submitError
 * @param {Function} throwError
 * @description Form that handles interfacing with Recurly API
 */
export default class RecurlyForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      /**
       * @type {Array[Objects]} see above
       */
      ...initialInputState(props),
      /**
       * @type {Array[Strings]} fields returned from Recurly API as causing error
       */
      errorFields: [],
      /**
       * @type {Boolean} whether error message modal shown to user
       */
      errorModalOpen: false,
      /**
       * @type {Boolean} whether user will be signed up for Hype newsletter upon form submission
       */
      optInNewsletter: false,
      /**
       * @type {Object} whether user has agreed to terms and conditions
       */
      optInTC: { checked: false, error: false },
      /**
       * @type {Boolean} whether Recurly script has been mounted to page
       */
      scriptLoaded: false,
    };
    this.formRef = createRef();
  }

  async componentDidMount () {
    await this.populateBillingInfo();
    this.initRecurly(() => {
      this.setState({ scriptLoaded: true }, () => recurly.configure(recurlyConfig));
    });
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.paymentMethod !== prevProps.paymentMethod ||
      (this.props.isEditable !== prevProps.isEditable && this.props.isEditable === true )
    ) {
      this.rebootRecurly();
      if (this.props.paymentMethod === 'paypal') {
        const { onFormSubmit, throwError } = this.props;
        const { country } = this.state;
        const currencyCode = currencyMap[country.value];

        this.paypal = recurly.PayPal({ display: { displayName: 'Hype Subscription' } });
        this.paypal.on('error', (err) => {
          this.setState({ errorFields: err.fields || [], errorModalOpen: true });
          throwError(err);
        });
        this.paypal.on('token', (token) => {
          token.id && onFormSubmit(token, this.state.optInNewsletter, currencyCode);
        });
      } else {
        this.paypal = null;
      }
    }
  }

  componentWillUnmount () {
    const script = document.getElementById('recurly-script');
    script.parentNode.removeChild(script);
  }

  initRecurly = (callback) => {
    const script = document.createElement('script');
    script.id = 'recurly-script';
    script.src = 'https://js.recurly.com/v4/recurly.js';
    script.type = 'text/javascript';
    script.async = true;
    script.onload = callback;
    document.body.appendChild(script);
  };

  rebootRecurly = () => {
    const script = document.getElementById('recurly-script');
    script.parentNode.removeChild(script);
    this.initRecurly(() => recurly.configure(recurlyConfig));
  };

  handleInputChange = (e, inputName) => {
    this.setState({ [inputName]: { ...this.handleOnChangeValidation(e, inputName) } });
  }

  handleOnChangeValidation = (e, inputName) => {
    const { value } = e.target;
    const newInputState = { ...this.state[inputName] };
    const isAlpha = /(^[A-Za-zs-]+$|^$)/i;
    const isAmerican = this.state.country.value === 'US';
    const updateValue = (conditions) => {
      newInputState.value = conditions
        ? value
        : newInputState.value;
    };
    switch (inputName) {
      case 'state':
        updateValue(
          isAlpha.test(value) &&
          (isAmerican && value.length <= 2 || !isAmerican)
        );
        break;
      default:
        updateValue(true);
        break;
    }
    return { ...newInputState };
  }

  handleInputValidation = (inputName, force) => {
    const { country, errorFields, state } = this.state;
    const isRejected = errorFields.indexOf(inputName) >= 0;
    const validation = {
      error: force,
      message: force && isRejected ? 'We were unable to process this field' : ''
    };
    const inputObj = this.state[inputName];
    const isCard = (inputName === 'number' || inputName === 'month' || inputName === 'year' || inputName === 'cvv');
    const isAmerican = country.value === 'US';

    if (inputObj.value.trim() === '' && inputObj.required && !(isCard && !isRejected)) {
      validation.error = true;
      validation.message = isRejected ? validation.message : 'This field cannot be empty';
      validation.isValidated = false;
    } else if (
      inputName === 'state' && isAmerican &&
        (stateCodes.indexOf(state.value.toUpperCase()) === -1)
    ) {
      validation.error = true;
      validation.message = 'Please enter a valid state';
      validation.isValidated = false;
    } else if (!force || (isCard && !isRejected)) {
      validation.error = false;
      validation.message = '';
      validation.isValidated = true;
    }

    this.setState({ [inputName]: { ...inputObj, ...validation } });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { isCheckout, isEditable, onFormSubmit, paymentMethod } = this.props;
    const { country, optInNewsletter, optInTC } = this.state;
    const currencyCode = currencyMap[country.value];

    //PayPal Flow
    if (paymentMethod === 'paypal') {
      return this.paypal.start();
    }

    //Card flow
    if (isCheckout && !optInTC.checked) {
      return this.setState({ optInTC: { ...optInTC, error: true } });
    }

    if (!isEditable) {
      return onFormSubmit(null, null, currencyCode);
    }

    recurly.token(this.formRef.current, (err, token) => {
      if (err) {
        this.setState({ errorFields: err.fields, errorModalOpen: true }, () => {
          Object.values(allInputFields).forEach(field => {
            this.handleInputValidation(field, (err.fields.indexOf(field) !== -1));
          });
        });
      } else {
        onFormSubmit(token, optInNewsletter, currencyCode);
      }
    });
  }

  populateBillingInfo = () => {
    const { billingInfo } = this.props;
    if (billingInfo) {
      const newState = Object.entries(billingInfo).reduce((billingState, [key, value]) => {
        switch (key) {
          case 'zip':
            billingState.postal_code = { ...this.state.postal_code, value };
            break;
          case 'last_four':
            billingState.number = { ...this.state.number, value: `************${value}` };
            break;
          default:
            if (this.state[key] && value) {
              billingState[key] = { ...this.state[key], value };
            }
        }
        return billingState;
      }, {});
      this.setState(newState);
    }
  }

  inputClassNames = (inputVal) => {
    let className = 'RecurlyForm__group';
    switch (inputVal) {
      case 'first_name':
      case 'last_name':
      case 'state':
      case 'postal_code':
        className = `${className} ${className}__half`;
        break;
      case 'address1':
      case 'city':
        className = `${className} ${className}__topmargin`;
        break;
      default:
        break;
    }
    return className;
  }

  renderInputFields = (names, isCard) => {
    return Object.entries(names).map(([inputKey, inputName]) => {
      const hasError = this.state[inputName].error && this.state[inputName].required;
      const isValidated = this.state[inputName].isValidated && this.state[inputName].required;
      const validationClass = () => {
        let className = '';
        className = hasError ? className + ' error' : className;
        className = isValidated ? className + ' validated' : className;
        return className;
      };
      return isCard ? (
        <div
          className={`RecurlyForm__input-${inputName}`}
          data-recurly={inputName}
          key={inputName}
        />
      ) : (this.state[inputName].renders && inputKey !== 'Country') && (
        <div
          className={`${this.inputClassNames(inputName)} ${validationClass}`}
          key={inputName}
          style={{ display: inputKey === 'Country' ? 'none' : 'relative' }}
        >
          <input
            className={hasError ? 'error' : ''}
            data-recurly={inputName}
            name={inputName}
            onBlur={() => { this.handleInputValidation(inputName); }}
            onChange={(e) => { this.handleInputChange(e, inputName); }}
            placeholder={inputKey}
            value={this.state[inputName].value}
          />
          <div className={`RecurlyForm__group-focus-label ${validationClass()}`}>
            {inputKey}
          </div>
          {isValidated && this.props.isEditable && (
            <div className="RecurlyForm__group-focus-label__icon">
              <IconCheck style={{ fill: '#94d500' }} />
            </div>
          )}
          { hasError && this.props.isEditable && (
            <div className="RecurlyForm__group-focus-label__icon">
              <IconError style={{ fill: '#d42a2a' }} />
            </div>
          )}
          <label htmlFor={inputName}>{inputKey}</label>
          { this.state[inputName].message && (
            <div className="RecurlyForm__group-message">
              {this.state[inputName].message}
            </div>
          )}
        </div>
      );
    });
  }

  renderStaticCardInfo = () => {
    return this.props.billingInfo && (
      <div className="RecurlyForm__group">
        <div className="RecurlyForm__group-focus-label RecurlyForm__group-focus-label__card">
          Card Number
        </div>
        <div className="RecurlyForm__group__card">
          { this.state.number.value }
        </div>
      </div>
    );
  }

  renderCountrySelect = () => {
    return this.props.isEditable ? (
      <select
        className="RecurlyForm__group-select"
        data-recurly="country"
        defaultValue={this.props.billingInfo ? this.props.billingInfo.country : 'US'}
        onChange={(e) => {
          this.setState({
            country: { ...this.state.country, value: e.target.value },
            state: { ...this.state.state, renders: needsState.indexOf(e.target.value) !== -1 ? true : false }
          });
        }}
      >
        {countryCodes.map(country => {
          const code = country['alpha-2'];
          return (
            <option key={code} value={code}>{ country.name }</option>
          );
        })}
      </select>
    ) : (
      <div className="RecurlyForm__group">
        <div className="RecurlyForm__group-focus-label RecurlyForm__group-focus-label__card">
            Country
        </div>
        <div className="RecurlyForm__group__card">
          { countryCodes.filter(cc => cc['alpha-2'] === this.state.country.value)[0].name }
        </div>
      </div>
    );
  };

  planPrice = () => {
    const currencyCode = currencyMap[this.state.country.value];
    const { planPrice } = this.props;
    return (
      <div className="PlanPrice">
        <div className="PlanPrice__number">
          { planPrice[currencyCode] || planPrice.USD }
        </div>
        <div className="PlanPrice__code">
          { currencyCode || 'USD' }
        </div>
      </div>
    );
  };

  buttonText = () => {
    const { isCheckout, paymentMethod } = this.props;
    if (isCheckout) {
      const mode = paymentMethod === 'card' ? 'Start Subscription' : 'Checkout With PayPal';
      return (
        <div className="RecurlyForm__button-text">
          <div className="RecurlyForm__button-text__mode">{ mode } for </div>
          { this.planPrice() }*
        </div>
      );
    }
    return 'Update Billing Info';
  }

  render () {
    const {
      billingInfo,
      isCheckout,
      isEditable,
      loading,
      paymentMethod,
      resetPaymentMethod,
      setEditable,
      setSubmitErrorState,
      submitError
    } = this.props;
    const {
      cvv,
      errorFields,
      errorModalOpen,
      month,
      number,
      optInNewsletter,
      optInTC,
      year
    } = this.state;
    const isCard = paymentMethod === 'card';
    const hasCardError = number.error || month.error || year.error || cvv.error;
    const renderCardFields = isEditable ? this.renderInputFields : this.renderStaticCardInfo;
    const renderFormFields = (!billingInfo || paymentMethod === 'card');

    return (
      <form
        className={`RecurlyForm ${isEditable ? '' : 'static'}`}
        id="RecurlyForm"
        ref={this.formRef}
      >
        {renderFormFields && (
          <Fragment>
            {(!isCheckout || !!billingInfo) && (
              <div
                className="RecurlyForm__edit-btn"
                onClick={() => {
                  resetPaymentMethod();
                  setEditable();
                }}
              >
                { isEditable ? 'Cancel' : 'Edit' }
              </div>
            )}
            {isCard && (
              <Fragment>
                <div className="RecurlyForm__section-label">Payment Details</div>
                <div className="RecurlyForm__section-card">
                  { renderCardFields(cardFieldNames, isCard) }
                </div>
                {hasCardError && (
                  <div className="RecurlyForm__group-message">
                    There was a problem processing your card
                  </div>
                )}
              </Fragment>
            )}
            <div className="RecurlyForm__section-label">Billing Details</div>
            <div className="RecurlyForm__section-fieldset">
              { this.renderInputFields(inputNames) }
              { this.renderCountrySelect() }
            </div>
            <input type="hidden" name="recurly-token" data-recurly="token" />
          </Fragment>
        )}
        {isCheckout && (
          <Fragment>
            <Checkbox
              checked={optInNewsletter}
              checkMark
              className="RecurlyForm__opt-in"
              id="optInNewsletter"
              label="Sign me up for the Hype newsletter"
              noMargin
              onChange={() => { this.setState({ optInNewsletter: !optInNewsletter }); }}
            />
            <div className="tc-optin">
              <Checkbox
                checked={optInTC.checked}
                checkMark
                className="RecurlyForm__opt-in"
                id="optInTC"
                noMargin
                onChange={() => {
                  this.setState({ optInTC: { checked: !optInTC.checked, error: optInTC.checked === true } });
                }}
              />
              I accept the
              <a
                href="https://support.beatport.com/hc/en-us/articles/215996708-Terms-and-Conditions#hype"
                target="_blank"
                rel="noopener noreferrer"
              >
                  terms and conditions.
              </a>
            </div>
            { optInTC.error && <div className="tc-optin__msg">Please accept the terms and conditions.</div> }
          </Fragment>
        )}
        {(isEditable || isCheckout) && (
          <Button
            category="hype"
            disabled={!optInTC.checked && isCheckout}
            loading={loading}
            onClick={this.handleFormSubmit}
            style={{ width: '100%', marginTop: '2rem', height: '4rem' }}
            className="RecurlyForm__button-blue"
          >
            { this.buttonText() }
          </Button>
        )}
        <Modal
          onClose={() => {
            this.setState({ errorModalOpen: false });
            setSubmitErrorState(false);
          }}
          open={errorModalOpen || submitError}
          showCloseButton
        >
          <div>
            {submitError
              ? 'There seems to be a problem processing your payment.'
              : 'There seems to be a problem with your billing information.'
            }
          </div>
          {errorFields.length > 0 && (
            <Fragment>
              <div>Please review the following fields:</div>
              <ul>{ errorFields.sort().map(ef => <li key={ef}>{errorFieldNames[ef]}</li>) }</ul>
            </Fragment>
          )}
          {submitError && (
            <div>
              Please contact&nbsp;
              <a href="mailto:support@beatport.com">customer support</a>
              &nbsp;if the problem persists.
            </div>
          )}
        </Modal>
      </form>
    );
  }
}
