// PACKAGES
import React from 'react';
import ReactDOM from 'react-dom';
// COMPONENTS
import App from './App';
// STYLES
import './main.scss';

const container = document.createElement('div');
container.id = 'main';
document.body.appendChild(container);

ReactDOM.render(<App />, container);
