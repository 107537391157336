export const recurlyConfig = {
  publicKey: Config.RECURLY_PUBLIC_KEY,
  required: ['number', 'month', 'year', 'cvv'],
  fields: {
    all: {
      style: {
        fontSize: '11px',
        fontColor: 'white',
        placeholder: {
          color: '#6c6c6c !important',
        }
      }
    },
    card: {
      displayIcon: false,
    },
    number: {
      style: {
        placeholder: {
          content: 'Card Number',
        },
        invalid: {
          borderBottom: '2px solid #d42a2a',
          fontColor: '#d42a2a'
        }
      }
    },
    month: {
      style: {
        placeholder: {
          content: 'Mon',
        },
        invalid: {
          borderBottom: '2px solid #d42a2a',
          fontColor: '#d42a2a'
        }
      }
    },
    year: {
      style: {
        placeholder: {
          content: 'Year',
        },
        invalid: {
          borderBottom: '2px solid #d42a2a',
          fontColor: '#d42a2a'
        }
      }
    },
    cvv: {
      style: {
        placeholder: {
          content: 'CVV',
        },
        invalid: {
          borderBottom: '2px solid #d42a2a',
          fontColor: '#d42a2a'
        }
      }
    }
  }
};

export const inputNames = {
  'First Name': 'first_name',
  'Last Name': 'last_name',
  'Street Address': 'address1',
  'Unit/Suite': 'address2',
  'City': 'city',
  'State': 'state',
  'Zip/Postal': 'postal_code',
  'Country': 'country',
};

export const cardFieldNames = {
  'Card Number': 'number',
  'Mon': 'month',
  'Year': 'year',
  'CVV': 'cvv',
};

export const errorFieldNames = {
  'number': 'card number',
  'month': 'card month',
  'year': 'card year',
  'cvv': 'card cvv',
  'address1': 'address',
  'state': 'state',
  'postal_code': 'zip/postal code',
  'first_name': 'first name',
  'last_name': 'last name'
};

export const currencyMap = {
  AD: 'EUR',
  AU: 'AUD',
  AT: 'EUR',
  BE: 'EUR',
  BG: 'EUR',
  CV: 'EUR',
  CY: 'EUR',
  DK: 'EUR',
  EE: 'EUR',
  FO: 'EUR',
  FI: 'EUR',
  FR: 'EUR',
  GF: 'EUR',
  TF: 'EUR',
  DE: 'EUR',
  GI: 'GBP',
  GR: 'EUR',
  GL: 'EUR',
  GP: 'EUR',
  VA: 'EUR',
  IE: 'EUR',
  IT: 'EUR',
  JE: 'GBP',
  LV: 'EUR',
  LT: 'EUR',
  LU: 'EUR',
  MT: 'EUR',
  MQ: 'EUR',
  YT: 'EUR',
  MC: 'EUR',
  MA: 'EUR',
  NL: 'EUR',
  PT: 'EUR',
  RE: 'EUR',
  PM: 'EUR',
  SM: 'EUR',
  SI: 'EUR',
  ES: 'EUR',
  SE: 'EUR',
  GB: 'GBP'
};

export const countryCodes = [
  { 'alpha-2': 'AF', name: 'Afghanistan' },
  { 'alpha-2': 'AX', name: 'Åland Islands' },
  { 'alpha-2': 'AL', name: 'Albania' },
  { 'alpha-2': 'DZ', name: 'Algeria' },
  { 'alpha-2': 'AS', name: 'American Samoa' },
  { 'alpha-2': 'AD', name: 'Andorra' },
  { 'alpha-2': 'AO', name: 'Angola' },
  { 'alpha-2': 'AI', name: 'Anguilla' },
  { 'alpha-2': 'AQ', name: 'Antarctica' },
  { 'alpha-2': 'AG', name: 'Antigua and Barbuda' },
  { 'alpha-2': 'AR', name: 'Argentina' },
  { 'alpha-2': 'AM', name: 'Armenia' },
  { 'alpha-2': 'AW', name: 'Aruba' },
  { 'alpha-2': 'AU', name: 'Australia' },
  { 'alpha-2': 'AT', name: 'Austria' },
  { 'alpha-2': 'AZ', name: 'Azerbaijan' },
  { 'alpha-2': 'BS', name: 'Bahamas' },
  { 'alpha-2': 'BH', name: 'Bahrain' },
  { 'alpha-2': 'BD', name: 'Bangladesh' },
  { 'alpha-2': 'BB', name: 'Barbados' },
  { 'alpha-2': 'BY', name: 'Belarus' },
  { 'alpha-2': 'BE', name: 'Belgium' },
  { 'alpha-2': 'BZ', name: 'Belize' },
  { 'alpha-2': 'BJ', name: 'Benin' },
  { 'alpha-2': 'BM', name: 'Bermuda' },
  { 'alpha-2': 'BT', name: 'Bhutan' },
  { 'alpha-2': 'BO', name: 'Bolivia (Plurinational State of)' },
  { 'alpha-2': 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  { 'alpha-2': 'BA', name: 'Bosnia and Herzegovina' },
  { 'alpha-2': 'BW', name: 'Botswana' },
  { 'alpha-2': 'BV', name: 'Bouvet Island' },
  { 'alpha-2': 'BR', name: 'Brazil' },
  { 'alpha-2': 'IO', name: 'British Indian Ocean Territory' },
  { 'alpha-2': 'BN', name: 'Brunei Darussalam' },
  { 'alpha-2': 'BG', name: 'Bulgaria' },
  { 'alpha-2': 'BF', name: 'Burkina Faso' },
  { 'alpha-2': 'BI', name: 'Burundi' },
  { 'alpha-2': 'CV', name: 'Cabo Verde' },
  { 'alpha-2': 'KH', name: 'Cambodia' },
  { 'alpha-2': 'CM', name: 'Cameroon' },
  { 'alpha-2': 'CA', name: 'Canada' },
  { 'alpha-2': 'KY', name: 'Cayman Islands' },
  { 'alpha-2': 'CF', name: 'Central African Republic' },
  { 'alpha-2': 'TD', name: 'Chad' },
  { 'alpha-2': 'CL', name: 'Chile' },
  { 'alpha-2': 'CN', name: 'China' },
  { 'alpha-2': 'CX', name: 'Christmas Island' },
  { 'alpha-2': 'CC', name: 'Cocos (Keeling) Islands' },
  { 'alpha-2': 'CO', name: 'Colombia' },
  { 'alpha-2': 'KM', name: 'Comoros' },
  { 'alpha-2': 'CG', name: 'Congo' },
  { 'alpha-2': 'CD', name: 'Congo, Democratic Republic of the' },
  { 'alpha-2': 'CK', name: 'Cook Islands' },
  { 'alpha-2': 'CR', name: 'Costa Rica' },
  { 'alpha-2': 'CI', name: 'Côte d\'Ivoire' },
  { 'alpha-2': 'HR', name: 'Croatia' },
  { 'alpha-2': 'CU', name: 'Cuba' },
  { 'alpha-2': 'CW', name: 'Curaçao' },
  { 'alpha-2': 'CY', name: 'Cyprus' },
  { 'alpha-2': 'CZ', name: 'Czechia' },
  { 'alpha-2': 'DK', name: 'Denmark' },
  { 'alpha-2': 'DJ', name: 'Djibouti' },
  { 'alpha-2': 'DM', name: 'Dominica' },
  { 'alpha-2': 'DO', name: 'Dominican Republic' },
  { 'alpha-2': 'EC', name: 'Ecuador' },
  { 'alpha-2': 'EG', name: 'Egypt' },
  { 'alpha-2': 'SV', name: 'El Salvador' },
  { 'alpha-2': 'GQ', name: 'Equatorial Guinea' },
  { 'alpha-2': 'ER', name: 'Eritrea' },
  { 'alpha-2': 'EE', name: 'Estonia' },
  { 'alpha-2': 'SZ', name: 'Eswatini' },
  { 'alpha-2': 'ET', name: 'Ethiopia' },
  { 'alpha-2': 'FK', name: 'Falkland Islands (Malvinas)' },
  { 'alpha-2': 'FO', name: 'Faroe Islands' },
  { 'alpha-2': 'FJ', name: 'Fiji' },
  { 'alpha-2': 'FI', name: 'Finland' },
  { 'alpha-2': 'FR', name: 'France' },
  { 'alpha-2': 'GF', name: 'French Guiana' },
  { 'alpha-2': 'PF', name: 'French Polynesia' },
  { 'alpha-2': 'TF', name: 'French Southern Territories' },
  { 'alpha-2': 'GA', name: 'Gabon' },
  { 'alpha-2': 'GM', name: 'Gambia' },
  { 'alpha-2': 'GE', name: 'Georgia' },
  { 'alpha-2': 'DE', name: 'Germany' },
  { 'alpha-2': 'GH', name: 'Ghana' },
  { 'alpha-2': 'GI', name: 'Gibraltar' },
  { 'alpha-2': 'GR', name: 'Greece' },
  { 'alpha-2': 'GL', name: 'Greenland' },
  { 'alpha-2': 'GD', name: 'Grenada' },
  { 'alpha-2': 'GP', name: 'Guadeloupe' },
  { 'alpha-2': 'GU', name: 'Guam' },
  { 'alpha-2': 'GT', name: 'Guatemala' },
  { 'alpha-2': 'GG', name: 'Guernsey' },
  { 'alpha-2': 'GN', name: 'Guinea' },
  { 'alpha-2': 'GW', name: 'Guinea-Bissau' },
  { 'alpha-2': 'GY', name: 'Guyana' },
  { 'alpha-2': 'HT', name: 'Haiti' },
  { 'alpha-2': 'HM', name: 'Heard Island and McDonald Islands' },
  { 'alpha-2': 'VA', name: 'Holy See' },
  { 'alpha-2': 'HN', name: 'Honduras' },
  { 'alpha-2': 'HK', name: 'Hong Kong' },
  { 'alpha-2': 'HU', name: 'Hungary' },
  { 'alpha-2': 'IS', name: 'Iceland' },
  { 'alpha-2': 'IN', name: 'India' },
  { 'alpha-2': 'ID', name: 'Indonesia' },
  { 'alpha-2': 'IR', name: 'Iran (Islamic Republic of)' },
  { 'alpha-2': 'IQ', name: 'Iraq' },
  { 'alpha-2': 'IE', name: 'Ireland' },
  { 'alpha-2': 'IM', name: 'Isle of Man' },
  { 'alpha-2': 'IL', name: 'Israel' },
  { 'alpha-2': 'IT', name: 'Italy' },
  { 'alpha-2': 'JM', name: 'Jamaica' },
  { 'alpha-2': 'JP', name: 'Japan' },
  { 'alpha-2': 'JE', name: 'Jersey' },
  { 'alpha-2': 'JO', name: 'Jordan' },
  { 'alpha-2': 'KZ', name: 'Kazakhstan' },
  { 'alpha-2': 'KE', name: 'Kenya' },
  { 'alpha-2': 'KI', name: 'Kiribati' },
  { 'alpha-2': 'KP', name: 'Korea (Democratic People\'s Republic of)' },
  { 'alpha-2': 'KR', name: 'Korea, Republic of' },
  { 'alpha-2': 'KW', name: 'Kuwait' },
  { 'alpha-2': 'KG', name: 'Kyrgyzstan' },
  { 'alpha-2': 'LA', name: 'Lao People\'s Democratic Republic' },
  { 'alpha-2': 'LV', name: 'Latvia' },
  { 'alpha-2': 'LB', name: 'Lebanon' },
  { 'alpha-2': 'LS', name: 'Lesotho' },
  { 'alpha-2': 'LR', name: 'Liberia' },
  { 'alpha-2': 'LY', name: 'Libya' },
  { 'alpha-2': 'LI', name: 'Liechtenstein' },
  { 'alpha-2': 'LT', name: 'Lithuania' },
  { 'alpha-2': 'LU', name: 'Luxembourg' },
  { 'alpha-2': 'MO', name: 'Macao' },
  { 'alpha-2': 'MG', name: 'Madagascar' },
  { 'alpha-2': 'MW', name: 'Malawi' },
  { 'alpha-2': 'MY', name: 'Malaysia' },
  { 'alpha-2': 'MV', name: 'Maldives' },
  { 'alpha-2': 'ML', name: 'Mali' },
  { 'alpha-2': 'MT', name: 'Malta' },
  { 'alpha-2': 'MH', name: 'Marshall Islands' },
  { 'alpha-2': 'MQ', name: 'Martinique' },
  { 'alpha-2': 'MR', name: 'Mauritania' },
  { 'alpha-2': 'MU', name: 'Mauritius' },
  { 'alpha-2': 'YT', name: 'Mayotte' },
  { 'alpha-2': 'MX', name: 'Mexico' },
  { 'alpha-2': 'FM', name: 'Micronesia (Federated States of)' },
  { 'alpha-2': 'MD', name: 'Moldova, Republic of' },
  { 'alpha-2': 'MC', name: 'Monaco' },
  { 'alpha-2': 'MN', name: 'Mongolia' },
  { 'alpha-2': 'ME', name: 'Montenegro' },
  { 'alpha-2': 'MS', name: 'Montserrat' },
  { 'alpha-2': 'MA', name: 'Morocco' },
  { 'alpha-2': 'MZ', name: 'Mozambique' },
  { 'alpha-2': 'MM', name: 'Myanmar' },
  { 'alpha-2': 'NA', name: 'Namibia' },
  { 'alpha-2': 'NR', name: 'Nauru' },
  { 'alpha-2': 'NP', name: 'Nepal' },
  { 'alpha-2': 'NL', name: 'Netherlands' },
  { 'alpha-2': 'NC', name: 'New Caledonia' },
  { 'alpha-2': 'NZ', name: 'New Zealand' },
  { 'alpha-2': 'NI', name: 'Nicaragua' },
  { 'alpha-2': 'NE', name: 'Niger' },
  { 'alpha-2': 'NG', name: 'Nigeria' },
  { 'alpha-2': 'NU', name: 'Niue' },
  { 'alpha-2': 'NF', name: 'Norfolk Island' },
  { 'alpha-2': 'MK', name: 'North Macedonia' },
  { 'alpha-2': 'MP', name: 'Northern Mariana Islands' },
  { 'alpha-2': 'NO', name: 'Norway' },
  { 'alpha-2': 'OM', name: 'Oman' },
  { 'alpha-2': 'PK', name: 'Pakistan' },
  { 'alpha-2': 'PW', name: 'Palau' },
  { 'alpha-2': 'PS', name: 'Palestine, State of' },
  { 'alpha-2': 'PA', name: 'Panama' },
  { 'alpha-2': 'PG', name: 'Papua New Guinea' },
  { 'alpha-2': 'PY', name: 'Paraguay' },
  { 'alpha-2': 'PE', name: 'Peru' },
  { 'alpha-2': 'PH', name: 'Philippines' },
  { 'alpha-2': 'PN', name: 'Pitcairn' },
  { 'alpha-2': 'PL', name: 'Poland' },
  { 'alpha-2': 'PT', name: 'Portugal' },
  { 'alpha-2': 'PR', name: 'Puerto Rico' },
  { 'alpha-2': 'QA', name: 'Qatar' },
  { 'alpha-2': 'RE', name: 'Réunion' },
  { 'alpha-2': 'RO', name: 'Romania' },
  { 'alpha-2': 'RU', name: 'Russian Federation' },
  { 'alpha-2': 'RW', name: 'Rwanda' },
  { 'alpha-2': 'BL', name: 'Saint Barthélemy' },
  { 'alpha-2': 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { 'alpha-2': 'KN', name: 'Saint Kitts and Nevis' },
  { 'alpha-2': 'LC', name: 'Saint Lucia' },
  { 'alpha-2': 'MF', name: 'Saint Martin (French part)' },
  { 'alpha-2': 'PM', name: 'Saint Pierre and Miquelon' },
  { 'alpha-2': 'VC', name: 'Saint Vincent and the Grenadines' },
  { 'alpha-2': 'WS', name: 'Samoa' },
  { 'alpha-2': 'SM', name: 'San Marino' },
  { 'alpha-2': 'ST', name: 'Sao Tome and Principe' },
  { 'alpha-2': 'SA', name: 'Saudi Arabia' },
  { 'alpha-2': 'SN', name: 'Senegal' },
  { 'alpha-2': 'RS', name: 'Serbia' },
  { 'alpha-2': 'SC', name: 'Seychelles' },
  { 'alpha-2': 'SL', name: 'Sierra Leone' },
  { 'alpha-2': 'SG', name: 'Singapore' },
  { 'alpha-2': 'SX', name: 'Sint Maarten (Dutch part)' },
  { 'alpha-2': 'SK', name: 'Slovakia' },
  { 'alpha-2': 'SI', name: 'Slovenia' },
  { 'alpha-2': 'SB', name: 'Solomon Islands' },
  { 'alpha-2': 'SO', name: 'Somalia' },
  { 'alpha-2': 'ZA', name: 'South Africa' },
  { 'alpha-2': 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { 'alpha-2': 'SS', name: 'South Sudan' },
  { 'alpha-2': 'ES', name: 'Spain' },
  { 'alpha-2': 'LK', name: 'Sri Lanka' },
  { 'alpha-2': 'SD', name: 'Sudan' },
  { 'alpha-2': 'SR', name: 'Suriname' },
  { 'alpha-2': 'SJ', name: 'Svalbard and Jan Mayen' },
  { 'alpha-2': 'SE', name: 'Sweden' },
  { 'alpha-2': 'CH', name: 'Switzerland' },
  { 'alpha-2': 'SY', name: 'Syrian Arab Republic' },
  { 'alpha-2': 'TW', name: 'Taiwan, Province of China' },
  { 'alpha-2': 'TJ', name: 'Tajikistan' },
  { 'alpha-2': 'TZ', name: 'Tanzania, United Republic of' },
  { 'alpha-2': 'TH', name: 'Thailand' },
  { 'alpha-2': 'TL', name: 'Timor-Leste' },
  { 'alpha-2': 'TG', name: 'Togo' },
  { 'alpha-2': 'TK', name: 'Tokelau' },
  { 'alpha-2': 'TO', name: 'Tonga' },
  { 'alpha-2': 'TT', name: 'Trinidad and Tobago' },
  { 'alpha-2': 'TN', name: 'Tunisia' },
  { 'alpha-2': 'TR', name: 'Turkey' },
  { 'alpha-2': 'TM', name: 'Turkmenistan' },
  { 'alpha-2': 'TC', name: 'Turks and Caicos Islands' },
  { 'alpha-2': 'TV', name: 'Tuvalu' },
  { 'alpha-2': 'UG', name: 'Uganda' },
  { 'alpha-2': 'UA', name: 'Ukraine' },
  { 'alpha-2': 'AE', name: 'United Arab Emirates' },
  { 'alpha-2': 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
  { 'alpha-2': 'US', name: 'United States of America' },
  { 'alpha-2': 'UM', name: 'United States Minor Outlying Islands' },
  { 'alpha-2': 'UY', name: 'Uruguay' },
  { 'alpha-2': 'UZ', name: 'Uzbekistan' },
  { 'alpha-2': 'VU', name: 'Vanuatu' },
  { 'alpha-2': 'VE', name: 'Venezuela (Bolivarian Republic of)' },
  { 'alpha-2': 'VN', name: 'Viet Nam' },
  { 'alpha-2': 'VG', name: 'Virgin Islands (British)' },
  { 'alpha-2': 'VI', name: 'Virgin Islands (U.S.)' },
  { 'alpha-2': 'WF', name: 'Wallis and Futuna' },
  { 'alpha-2': 'EH', name: 'Western Sahara' },
  { 'alpha-2': 'YE', name: 'Yemen' },
  { 'alpha-2': 'ZM', name: 'Zambia' },
  { 'alpha-2': 'ZW', name: 'Zimbabwe' }
];

export const needsState = ['US', 'CA'];

export const stateCodes = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
