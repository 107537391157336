// PACKAGES
import React from 'react';
import { Button } from '@beatport/ui/Buttons';
//UTILS
import defaultImage from '../../images/default.jpg';
import { prettyDate } from '@lib/utils';
import { currencyMap } from '../RecurlyForm/RecurlyConfig';
// STYLES
import './LabelModule.scss';

/**
 * @type {SFC}
 * @param {String} country
 * @param {Boolean} loading
 * @param {Object} planPrice
 * @param {Object} subscription
 * @param {Function} subscriptionAction
 * @description Rendered as list of active subscriptions per label in `Account` page
 */
const LabelModule = ({ country, loading, planPrice, subscription, subscriptionAction }) => {
  const { label, recurly_subscription_status } = subscription;
  const endDate = prettyDate(subscription.end_date);
  const isCanceled = subscription.recurly_subscription_status === 'canceled';
  const action = isCanceled ? 'activate' : 'cancel';
  const code = currencyMap[country] || 'USD';
  const number = planPrice[code] || planPrice.USD;

  return (
    <div className="LabelModule">
      <div className="LabelModule__label-info">
        <img src={label.image ? label.image.uri : defaultImage} />
        <div className="LabelModule__label-info-wrapper">
          <div>{label.name} - <span>{recurly_subscription_status}</span></div>
          <div>{!isCanceled && `${number} ${code}/month  ∙`} {isCanceled ? 'Expires' : 'Next billing date'} {endDate} </div>
        </div>
      </div>
      <Button
        category="text"
        className="LabelModule__button"
        loading={loading}
        onClick={() => { subscriptionAction(action); }}
      >
        {action}
      </Button>
    </div>
  );
};

export default LabelModule;
