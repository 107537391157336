// PACKAGES
import React from 'react';
// STYLES
import './LabelBaseLogo.scss';

export const LabelBaseLogo = ({ className }) => {
  const classList = `LabelBaseLogo ${className || ''}`;
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 400 150"
      xmlSpace="preserve" className={classList}>
      <g>
        <path className="LabelBaseLogo__white" d="M55.77,83.58c0,11.15-8.9,20.17-20.17,20.17c-11.15,0-20.05-8.79-20.05-20.17c0-5.35,2.03-10.09,5.24-13.63
		L7.16,83.58L0,76.42L15.39,61.2c2.09-2.09,3.15-4.78,3.15-7.77v-19.1h10.09v19.1c0,5.86-2.09,10.82-6.14,14.87l-0.46,0.46
		c3.55-3.26,8.39-5.24,13.57-5.24C47.04,63.53,55.77,72.6,55.77,83.58 M46.65,83.58c0-5.97-4.96-10.82-11.04-10.82
		c-6.14,0-10.93,5.07-10.93,10.82c0,5.92,4.84,10.93,10.93,10.93C41.91,94.51,46.65,89.38,46.65,83.58"/>
        <path className="LabelBaseLogo__white" d="M88.89,63.99c4.23,0,7.51,0.89,9.84,2.65c2.33,1.76,3.51,4.23,3.51,7.39c0,2.32-0.62,4.22-1.85,5.72
		c-1.23,1.49-3.01,2.5-5.33,3.01c5.77,0.99,8.67,4.03,8.67,9.1c0,3.35-1.24,5.98-3.73,7.89c-2.49,1.91-6.03,2.87-10.62,2.87H74.7V64
		h14.19V63.99z M81.05,80.37h8c2.18,0,3.85-0.5,5.05-1.49c1.2-1,1.8-2.35,1.8-4.08s-0.6-3.09-1.8-4.05
		c-1.2-0.98-2.87-1.47-5.05-1.47h-8V80.37z M81.05,97.26h8.39c2.5,0,4.42-0.51,5.77-1.52c1.34-1.01,2.01-2.42,2.01-4.22
		c0-1.88-0.68-3.33-2.04-4.36c-1.37-1.03-3.27-1.54-5.74-1.54h-8.39L81.05,97.26L81.05,97.26z"/>
        <path className="LabelBaseLogo__white" d="M106.68,80.9c1.15-2.19,2.77-3.88,4.86-5.07c2.08-1.2,4.46-1.8,7.15-1.8c2.72,0,5.13,0.55,7.2,1.65
		c2.08,1.1,3.72,2.66,4.92,4.69c1.2,2.02,1.81,4.39,1.85,7.11c0,0.73-0.06,1.49-0.17,2.26h-21.3v0.33c0.14,2.46,0.92,4.42,2.32,5.85
		c1.4,1.43,3.25,2.15,5.57,2.15c1.84,0,3.39-0.43,4.64-1.3c1.25-0.87,2.08-2.09,2.49-3.67h5.96c-0.52,2.87-1.9,5.23-4.16,7.06
		c-2.26,1.84-5.08,2.76-8.47,2.76c-2.94,0-5.52-0.6-7.7-1.8c-2.19-1.2-3.88-2.87-5.07-5.05c-1.2-2.18-1.8-4.69-1.8-7.56
		C104.95,85.63,105.53,83.09,106.68,80.9 M126.58,85.4c-0.26-2.06-1.08-3.67-2.45-4.83c-1.38-1.15-3.12-1.74-5.22-1.74
		c-1.95,0-3.63,0.6-5.05,1.8c-1.42,1.2-2.23,2.79-2.45,4.77L126.58,85.4L126.58,85.4z"/>
        <path className="LabelBaseLogo__white" d="M161.52,97.43v5.18h-3.14c-1.84,0-3.16-0.39-3.97-1.15c-0.81-0.78-1.21-1.9-1.21-3.36
		c-2.13,3.24-5.24,4.86-9.32,4.86c-3.16,0-5.7-0.73-7.61-2.21c-1.91-1.48-2.87-3.5-2.87-6.07c0-2.91,1.03-5.14,3.09-6.7
		c2.06-1.56,5.06-2.34,8.99-2.34h7.11v-1.71c0-1.58-0.55-2.82-1.65-3.73c-1.1-0.9-2.63-1.35-4.58-1.35c-1.73,0-3.15,0.38-4.27,1.13
		c-1.12,0.75-1.8,1.75-2.02,3.01h-5.85c0.3-2.83,1.54-5.03,3.75-6.59c2.21-1.56,5.09-2.34,8.67-2.34c3.78,0,6.71,0.89,8.78,2.65
		c2.06,1.76,3.09,4.31,3.09,7.61v11.14c0,1.32,0.62,1.99,1.88,1.99h1.17V97.43z M152.58,90.03h-7.39c-1.84,0-3.25,0.36-4.25,1.08
		c-1,0.72-1.49,1.78-1.49,3.17c0,1.21,0.47,2.19,1.41,2.92c0.94,0.73,2.22,1.1,3.83,1.1c2.46,0,4.38-0.67,5.77-1.99
		c1.38-1.32,2.09-3.09,2.12-5.29V90.03z"/>
        <path className="LabelBaseLogo__white" d="M163.86,79.6h-4.92v-5.24h4.92v-7.89h6.02v7.89h6.79v5.24h-6.79v14.74c0,1.1,0.22,1.89,0.67,2.34
		c0.44,0.46,1.2,0.69,2.26,0.69h4.53v5.24h-5.74c-2.69,0-4.64-0.62-5.88-1.88c-1.23-1.25-1.85-3.19-1.85-5.79V79.6z"/>
        <path className="LabelBaseLogo__white" d="M200.92,75.76c2.02,1.15,3.61,2.83,4.75,5.03c1.14,2.19,1.71,4.75,1.71,7.7c0,2.86-0.57,5.39-1.71,7.59
		c-1.14,2.19-2.72,3.88-4.75,5.07c-2.02,1.2-4.33,1.8-6.89,1.8c-2.1,0-3.92-0.39-5.46-1.15c-1.54-0.78-2.77-1.82-3.7-3.14v15.01
		h-5.96V74.38h5.18l0.72,4.2c2.28-3.02,5.35-4.53,9.21-4.53C196.59,74.02,198.9,74.61,200.92,75.76 M199.01,95.28
		c1.53-1.76,2.29-4.03,2.29-6.79c0-2.8-0.77-5.06-2.29-6.79c-1.53-1.73-3.52-2.6-5.98-2.6c-2.46,0-4.45,0.85-5.96,2.56
		c-1.51,1.71-2.26,3.95-2.26,6.7c0,2.83,0.75,5.13,2.26,6.89c1.51,1.76,3.5,2.65,5.96,2.65C195.49,97.92,197.49,97.04,199.01,95.28"
        />
        <path className="LabelBaseLogo__white" d="M216.07,101.15c-2.19-1.2-3.88-2.89-5.07-5.07c-1.2-2.19-1.8-4.72-1.8-7.59c0-2.83,0.6-5.34,1.8-7.54
		c1.2-2.19,2.89-3.9,5.07-5.11c2.19-1.21,4.69-1.82,7.54-1.82c2.83,0,5.35,0.61,7.54,1.82c2.19,1.21,3.88,2.92,5.07,5.11
		c1.2,2.19,1.8,4.71,1.8,7.54c0,2.87-0.6,5.4-1.8,7.59s-2.89,3.88-5.07,5.07c-2.19,1.2-4.69,1.8-7.54,1.8
		C220.76,102.94,218.26,102.34,216.07,101.15 M229.64,95.24c1.53-1.74,2.29-4.01,2.29-6.76c0-2.76-0.77-5.02-2.29-6.76
		c-1.53-1.74-3.54-2.62-6.05-2.62c-2.5,0-4.51,0.88-6.02,2.62c-1.51,1.74-2.26,4.01-2.26,6.76c0,2.76,0.75,5.02,2.26,6.76
		c1.51,1.74,3.52,2.62,6.02,2.62C226.1,97.86,228.11,97,229.64,95.24"/>
        <path className="LabelBaseLogo__white" d="M255.66,74.35v5.57H253c-2.46,0-4.25,0.8-5.35,2.4c-1.11,1.6-1.65,3.63-1.65,6.09v14.18h-5.96V74.34h5.29
		l0.67,4.25c0.81-1.32,1.85-2.36,3.14-3.12c1.29-0.75,3.02-1.13,5.18-1.13h1.33V74.35z"/>
        <path className="LabelBaseLogo__white" d="M257.59,66.46h5.96v7.89h6.85v5.24h-6.85v14.74c0,1.1,0.22,1.89,0.67,2.34c0.44,0.47,1.2,0.69,2.26,0.69h4.53
		v5.24h-5.74c-2.69,0-4.64-0.62-5.85-1.88s-1.82-3.19-1.82-5.79V66.46H257.59z"/>
        <polygon className="LabelBaseLogo__blue" points="280.44,63.92 286.79,63.92 286.79,80.2 304.73,80.2 304.73,63.92 311.07,63.92 311.07,102.61
		304.73,102.61 304.73,85.83 286.79,85.83 286.79,102.61 280.44,102.61 	"/>
        <path className="LabelBaseLogo__blue" d="M318.52,74.35l7.89,21.81l7.67-21.81h6.35l-12.81,32.67c-0.67,1.7-1.27,2.99-1.82,3.9
		c-0.55,0.9-1.23,1.59-2.04,2.04c-0.81,0.46-1.9,0.69-3.25,0.69h-6.4v-5.25h4.2c1.14,0,1.93-0.18,2.38-0.52
		c0.44-0.36,0.9-1.13,1.38-2.34l1.15-2.71l-11.04-28.48H318.52z"/>
        <path className="LabelBaseLogo__blue" d="M363.46,75.75c2.02,1.15,3.61,2.83,4.75,5.03c1.14,2.19,1.71,4.76,1.71,7.7c0,2.86-0.57,5.4-1.71,7.59
		c-1.14,2.19-2.72,3.88-4.75,5.08c-2.02,1.2-4.33,1.8-6.9,1.8c-2.1,0-3.92-0.39-5.46-1.15c-1.54-0.78-2.79-1.82-3.7-3.14v15.02
		h-5.96v-39.3h5.18l0.72,4.2c2.29-3.02,5.35-4.53,9.22-4.53C359.13,74.02,361.43,74.6,363.46,75.75 M361.55,95.28
		c1.53-1.76,2.29-4.03,2.29-6.79c0-2.8-0.77-5.06-2.29-6.79c-1.53-1.73-3.53-2.6-5.99-2.6c-2.46,0-4.45,0.85-5.96,2.56
		s-2.26,3.95-2.26,6.7c0,2.83,0.75,5.14,2.26,6.9s3.5,2.65,5.96,2.65C358.02,97.92,360.02,97.04,361.55,95.28"/>
        <path className="LabelBaseLogo__blue" d="M373.02,80.89c1.15-2.19,2.77-3.88,4.86-5.08c2.08-1.2,4.46-1.8,7.15-1.8c2.72,0,5.13,0.55,7.2,1.65
		c2.08,1.11,3.72,2.66,4.92,4.69c1.2,2.02,1.81,4.39,1.85,7.13c0,0.73-0.06,1.49-0.17,2.26h-21.31v0.33
		c0.14,2.46,0.92,4.42,2.32,5.85c1.4,1.43,3.25,2.15,5.57,2.15c1.84,0,3.39-0.43,4.64-1.3c1.25-0.87,2.08-2.09,2.49-3.67h5.96
		c-0.52,2.86-1.91,5.23-4.17,7.06c-2.26,1.84-5.08,2.76-8.47,2.76c-2.94,0-5.52-0.6-7.7-1.8c-2.19-1.2-3.88-2.87-5.08-5.05
		s-1.8-4.69-1.8-7.56C371.28,85.63,371.86,83.08,373.02,80.89 M392.92,85.39c-0.26-2.06-1.08-3.67-2.45-4.83
		c-1.38-1.15-3.12-1.74-5.22-1.74c-1.95,0-3.63,0.6-5.05,1.8c-1.42,1.2-2.23,2.79-2.45,4.77L392.92,85.39L392.92,85.39z"/>
      </g>
    </svg>
  );
};
