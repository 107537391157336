// PACKAGES
import React, { Component } from 'react';
import { Button } from '@beatport/ui/Buttons';
import { Modal } from '@beatport/ui/Modal';
// COMPONENTS
import LabelModule from '@components/LabelModule/LabelModule';
import PaymentMethods, { defaultPaymentMethod } from '@components/PaymentMethods/PaymentMethods';
import RecurlyForm from '@components/RecurlyForm/RecurlyForm';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
// UTILS
import { currencyMap } from '@components/RecurlyForm/RecurlyConfig';
import { prettyDate } from '@lib/utils';

// STYLES
import './Account.scss';

/**
 * @type {React.Component}
 * @param {Object} billingInfo
 * @param {Object} plan
 * @param {Function} setBillingInfoState
 * @description Route for My Subscriptions and My Billing Info
 */
export default class Account extends Component {
  constructor (props) {
    super(props);
    this.state = {
      /**
       * @type {Array[Objects]} all subscriptions associated with a user currently in `live` state in bp-api
       */
      activeSubscriptions: [],
      /**
       * @type {String} either `Cancel` or `Reactivate` - determines behavior of LabelModule action button
       */
      clickAction: null,
      /**
       * @type {Boolean} whether confirmation modal is toggled
       */
      confirmModalOpen: false,
      /**
       * @type {Boolean} whether initial data fetching is complete
       */
      inited: false,
      /**
       * @type {Boolean} whether billing info form is currently editable
       */
      isEditable: false,
      /**
       * @type {Boolean} current loading state
       */
      loading: true,
      /**
       * @type {String} either `paypal` or `card` - determines how Recurly API interfaced with
       */
      paymentMethod: defaultPaymentMethod(props.billingInfo),
      /**
       * @type {Object} plan (for dynamic pricing context)
       */
      plan: props.plan,
      /**
       * @type {Number} id of currently selected LabelModule action will be applied to
       */
      selectedId: null,
      /**
       * @type {Boolean} whether billing info form modal is visible
       */
      showBillingModal: false,
      /**
       * @type {Boolean} whether there is an error with submitting the form
       */
      submitError: false,
    };
  }

  async componentDidMount () {
    await this.fetchSubscriptions();
    !this.props.billingInfo && await this.props.setBillingInfoState();
    !this.props.plan && await this.fetchPlan();
    this.setState({ loading: false, inited: true });
  }

  componentDidUpdate (prevProps) {
    (this.props.billingInfo && prevProps.billingInfo) &&
    (this.props.billingInfo.type !== prevProps.billingInfo.type) &&
      this.setState({ paymentMethod: defaultPaymentMethod(this.props.billingInfo) });
  }

  handleConfirmClick = async () => {
    this.setState({ confirmModalOpen: false, loading: true });
    try {
      const apiRoute = this.state.clickAction === 'cancel'
        ? BeatportAPI.cancelSubscription
        : BeatportAPI.reactivateSubscription;

      const { request } = apiRoute.put({ id: this.state.selectedId });
      await request;
      await this.fetchSubscriptions();
    } catch (err) {
      this.props.throwError(err);
      this.setState({ loading: false });
    } finally {
      this.setState({ selectedId: null });
    }
  }

  fetchSubscriptions = async () => {
    this.state.inited && this.setState({ loading: true });
    try {
      const { request } = BeatportAPI.subscriptions.get({ params: { per_page: 100 } });
      const response = await request;
      const activeSubscriptions = response.data.results;
      this.setState({ activeSubscriptions });
    } catch (err) {
      this.props.throwError(err);
    } finally {
      this.state.inited && this.setState({ loading: false });
    }
  }

  fetchPlan = async () => {
    try {
      const { request } = BeatportAPI.plans.get();
      const response = await request;
      this.setState({ plan: response.data.filter(plan => plan.plan_code.indexOf('hype') !== -1)[0] });
    } catch (err) {
      this.props.throwError(err);
    }
  }

  setSubmitErrorState = (submitError) => { this.setState({ submitError }); }

  selectSubscription = (id, action) => { this.setState({ clickAction: action, confirmModalOpen: true, selectedId: id }); }

  setEditable = () => { this.setState({ isEditable: !this.state.isEditable }); }

  toggleBillingModal = () => { this.setState({ isEditable: false, showBillingModal: !this.state.showBillingModal }); }

  updateBillingInfo = async (token, checkoutArg, currency = 'USD') => {
    this.setState({ loading: true });
    try {
      const payload = { billing_token: token ? token.id : '', currency };
      const { request } = BeatportAPI.billingInfo.put(payload);
      const response = await request;
      this.props.setBillingInfoState(response.data);
      this.setState({ showBillingModal: false });
    } catch (err) {
      this.props.throwError(err);
      this.setSubmitErrorState(true);
    } finally {
      this.setState({ loading: false });
    }
  }

  renderSubscriptions = () => {
    const { activeSubscriptions, loading, plan, selectedId } = this.state;
    return activeSubscriptions.length > 0
      ? (
        activeSubscriptions.map((subscription) => {
          const { id } = subscription;
          return (
            <div key={id} className="Account__subscription">
              <LabelModule
                country={this.props.billingInfo ? this.props.billingInfo.country : 'US'}
                loading={loading && id === selectedId}
                planPrice={plan ? plan.plan_price : { USD: '$9.99' }}
                subscriptionAction={(action) => { this.selectSubscription(id, action); }}
                subscription={subscription}
              />
            </div>
          );
        })
      ) : loading ? null : ('Looks like you don\'t have any subscriptions');
  }

  resetPaymentMethod = () => this.setState({ paymentMethod: defaultPaymentMethod(this.props.billingInfo) });

  render () {
    const {
      activeSubscriptions,
      clickAction,
      confirmModalOpen,
      loading,
      inited,
      isEditable,
      paymentMethod,
      plan,
      selectedId,
      showBillingModal,
      submitError
    } = this.state;
    const planPrice = plan ? plan.plan_price : { USD: '$9.99' };
    const selectedSubscription = activeSubscriptions.filter(sub => sub.id === selectedId)[0];
    const endDate = selectedSubscription ? prettyDate(selectedSubscription.end_date) : null;
    const code = this.props.billingInfo ? currencyMap[this.props.billingInfo.country] : 'USD';
    const number = planPrice[code] || planPrice.USD;

    return (loading && !inited) ? null : (
      <div className="Account">
        <h1 className="Account__heading">My Subscriptions</h1>
        <div className="Account__billing-show-btn">
          {this.props.billingInfo && (
            <Button category="hype" onClick={this.toggleBillingModal}>
                My Billing Info
            </Button>
          )}
        </div>
        <div className="Account__subscriptions">
          { this.renderSubscriptions() }
        </div>
        <Modal open={showBillingModal} onClose={() =>{
          this.toggleBillingModal();
          this.resetPaymentMethod();
        }}>
          <div className="Account__billing-modal">
            <PaymentMethods
              isEditable={isEditable}
              padded
              paymentMethod={paymentMethod}
              setPaymentMethod={(pm) => { this.setState({ paymentMethod: pm, isEditable: true }); }}
            />
            <RecurlyForm
              billingInfo={this.props.billingInfo}
              isEditable={isEditable || paymentMethod === 'paypal'}
              loading={loading}
              onFormSubmit={this.updateBillingInfo}
              paymentMethod={paymentMethod}
              resetPaymentMethod={this.resetPaymentMethod}
              setEditable={this.setEditable}
              setSubmitErrorState={this.setSubmitErrorState}
              submitError={submitError}
              throwError={this.props.throwError}
            />
          </div>
        </Modal>
        <Modal
          className="Account__confirm-modal"
          onClose={() => { this.setState({ confirmModalOpen: false, selectedId: null }); }}
          open={confirmModalOpen}
          showCloseButton
        >
          <div className="Account__confirm-modal-content">
            <div>
              {clickAction === 'cancel'
                ? 'Are you sure you want to cancel your subscription?'
                : 'Confirm activation'
              }
            </div>
            {clickAction === 'activate' && (
              <div className="activate">You will be charged {number}{code}/month starting {endDate}.</div>
            )}
            <Button
              category={clickAction === 'activate' ? 'hype' : 'warning'}
              className={clickAction !== 'activate' ? 'cancel_btn' : ''}
              onClick={this.handleConfirmClick}
              style={{ marginBottom: 0 }}
            >
              {clickAction} subscription
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
