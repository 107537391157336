// PACKAGES
import React from 'react';
// STYLES
import './HypeLogo.scss';

/**
 * @type {SFC}
 * @param {String} className
 * @description SVG for Hype Brand Logo
 */
export const HypeLogo = ({ className }) => {
  const classList = className ? `HypeLogo ${className}` : 'HypeLogo';
  return (
    <svg
      viewBox="0 0 720 115"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={classList}
    >
      <g className="rotate" style={{ fill: '#00bfff' }}>
        <g transform="translate(705, -51.1)">
          <path d="m 0,0 c -0.345,4.13 -2.306,7.964 -5.468,10.68 -3.287,2.635 -7.427,4.003 -11.653,3.853 -4.123,0.076 -8.133,-1.337 -11.281,-3.975 C -31.566,7.896 -33.534,4.098 -33.87,0 Z m -44.341,9.949 c 2.45,4.691 6.214,8.585 10.84,11.217 4.867,2.713 10.378,4.086 15.963,3.974 5.583,0.114 11.111,-1.143 16.085,-3.658 C 3.104,19.106 6.902,15.517 9.51,11.119 12.25,6.315 13.676,0.885 13.647,-4.633 13.633,-6.305 13.508,-7.975 13.278,-9.631 h -47.591 v -0.732 c 0.069,-4.794 1.917,-9.394 5.197,-12.924 1.601,-1.609 3.524,-2.874 5.643,-3.713 2.119,-0.839 4.391,-1.235 6.673,-1.163 3.663,-0.128 7.276,0.869 10.347,2.852 2.797,1.935 4.773,4.828 5.542,8.12 h 13.302 c -1.028,-6.164 -4.339,-11.728 -9.287,-15.606 -5.379,-4.185 -12.081,-6.353 -18.918,-6.12 -5.996,-0.142 -11.929,1.225 -17.243,3.975 -4.797,2.549 -8.736,6.431 -11.332,11.168 -2.764,5.134 -4.147,10.886 -4.015,16.703 -0.099,5.92 1.299,11.77 4.063,17.02"></path>
        </g>
        <g transform="translate(635,-73.2)">
          <path d="M 0,0 C 3.514,4.204 5.339,9.543 5.123,14.996 5.375,20.454 3.545,25.806 0,29.992 c -1.709,1.807 -3.776,3.247 -6.07,4.231 -2.294,0.985 -4.768,1.492 -7.268,1.492 -2.5,0 -4.977,-0.507 -7.271,-1.492 -2.294,-0.984 -4.36,-2.424 -6.069,-4.231 -3.5,-4.141 -5.301,-9.432 -5.049,-14.825 -0.259,-5.521 1.539,-10.943 5.049,-15.24 1.687,-1.843 3.746,-3.315 6.045,-4.323 2.297,-1.008 4.78,-1.528 7.295,-1.528 2.512,0 4.996,0.52 7.295,1.528 2.296,1.008 4.355,2.48 6.043,4.323 z M 4.262,43.111 C 8.805,40.506 12.485,36.652 14.853,32.016 17.507,26.718 18.818,20.859 18.672,14.947 18.789,9.132 17.478,3.375 14.853,-1.829 12.468,-6.485 8.793,-10.375 4.262,-13.046 c -4.681,-2.683 -10.013,-4.057 -15.421,-3.974 -4.255,-0.113 -8.474,0.772 -12.317,2.585 -3.305,1.581 -6.146,3.973 -8.251,6.949 v -33.187 h -13.302 v 86.905 h 11.576 l 1.601,-9.266 c 2.345,3.201 5.447,5.785 9.031,7.528 3.586,1.742 7.546,2.59 11.54,2.47 5.434,0.109 10.802,-1.221 15.543,-3.853"></path>
        </g>
        <g transform="translate(539,-27)">
          <path d="M 0,0 17.612,-48.183 34.855,0 H 49.02 L 20.273,-72.226 c -1.107,-2.969 -2.467,-5.841 -4.066,-8.583 -1.085,-1.877 -2.658,-3.434 -4.557,-4.511 -2.246,-1.124 -4.751,-1.653 -7.265,-1.536 H -9.903 v 11.631 h 9.361 c 1.846,-0.174 3.699,0.236 5.296,1.17 1.424,1.46 2.478,3.231 3.08,5.17 l 2.462,5.998 -24.634,62.936 z"></path>
        </g>
        <g transform="translate(454,-5)">
          <path d="M 0,0 H 14.115 V -35.967 H 54.17 V 0 H 68.308 V -85.515 H 54.143 v 37.088 H 14.115 V -85.515 H -0.05 Z"></path>
        </g>
      </g>
      <g className="rotate" style={{ fill: '#f1f1f2' }}>
        <g transform="translate(404,-10)">
          <path d="M 0,0 H 13.424 V -17.435 H 28.721 V -29.017 H 13.424 V -61.57 c -0.194,-1.854 0.336,-3.712 1.479,-5.194 0.7,-0.574 1.511,-1.004 2.383,-1.263 0.87,-0.26 1.786,-0.344 2.69,-0.248 h 10.102 v -11.607 h -12.96 c -5.976,0 -10.329,1.39 -13.055,4.169 -2.725,2.78 -4.08,7.047 -4.063,12.802 z"></path>
        </g>
        <g transform="translate(400,-28)">
          <path d="m 0,0 v -12.192 h -5.911 c -2.287,0.195 -4.589,-0.191 -6.681,-1.121 -2.096,-0.93 -3.917,-2.374 -5.291,-4.195 -2.599,-4.005 -3.888,-8.704 -3.694,-13.46 V -62.326 H -34.879 V 0 h 11.823 l 1.479,-9.388 c 1.733,2.835 4.145,5.204 7.019,6.901 3.586,1.801 7.587,2.642 11.602,2.438 z"></path>
        </g>
        <g transform="translate(342,-73)">
          <path d="m 0,0 c 3.308,4.291 5.099,9.538 5.099,14.935 0,5.397 -1.791,10.645 -5.099,14.936 -1.666,1.905 -3.747,3.416 -6.082,4.42 -2.337,1.005 -4.871,1.477 -7.417,1.383 -2.533,0.099 -5.06,-0.372 -7.385,-1.377 -2.326,-1.005 -4.39,-2.518 -6.039,-4.426 -3.277,-4.305 -5.046,-9.547 -5.046,-14.936 0,-5.388 1.769,-10.631 5.046,-14.935 1.657,-1.899 3.723,-3.405 6.046,-4.406 2.325,-1 4.847,-1.47 7.378,-1.373 2.544,-0.092 5.075,0.379 7.41,1.379 2.335,1 4.416,2.503 6.089,4.4 m -30.322,-13.046 c -4.799,2.568 -8.738,6.467 -11.332,11.217 -2.625,5.185 -3.991,10.904 -3.991,16.703 0,5.8 1.366,11.519 3.991,16.703 2.599,4.766 6.533,8.687 11.332,11.29 5.207,2.637 10.974,4.012 16.823,4.012 5.852,0 11.617,-1.375 16.826,-4.012 4.797,-2.603 8.733,-6.524 11.332,-11.29 2.625,-5.184 3.991,-10.903 3.991,-16.703 0,-5.799 -1.366,-11.518 -3.991,-16.703 -2.594,-4.75 -6.532,-8.649 -11.332,-11.217 -5.216,-2.613 -10.979,-3.975 -16.826,-3.975 -5.844,0 -11.609,1.362 -16.823,3.975"></path>
        </g>
        <g transform="translate(274,-72)">
          <path d="M 0,0 C 3.514,4.204 5.339,9.544 5.123,14.996 5.36,20.452 3.533,25.799 0,29.993 c -1.714,1.8 -3.78,3.234 -6.074,4.214 -2.295,0.981 -4.766,1.486 -7.266,1.486 -2.498,0 -4.97,-0.505 -7.264,-1.486 -2.294,-0.98 -4.36,-2.414 -6.074,-4.214 -3.443,-4.157 -5.2,-9.433 -4.927,-14.802 -0.278,-5.503 1.472,-10.919 4.927,-15.24 1.687,-1.842 3.746,-3.315 6.043,-4.322 2.299,-1.008 4.782,-1.529 7.295,-1.529 2.514,0 4.998,0.521 7.297,1.529 2.296,1.007 4.355,2.48 6.043,4.322 z M 4.262,43.111 C 8.807,40.5 12.487,36.637 14.853,31.992 17.509,26.694 18.82,20.835 18.672,14.923 18.792,9.107 17.48,3.35 14.853,-1.853 12.465,-6.509 8.793,-10.399 4.262,-13.07 c -4.681,-2.683 -10.013,-4.057 -15.421,-3.974 -4.255,-0.114 -8.476,0.772 -12.317,2.584 -3.301,1.589 -6.139,3.98 -8.253,6.95 V -40.697 H -45.03 v 86.905 h 11.577 l 1.601,-9.266 c 2.345,3.201 5.444,5.785 9.031,7.527 3.586,1.743 7.546,2.591 11.537,2.47 5.435,0.118 10.802,-1.204 15.546,-3.828"></path>
        </g>
        <g transform="translate(196,-38)">
          <path d="M 0,0 H -10.962 V 11.582 H 0 V 29.041 H 13.425 V 11.607 H 28.6 V 0.024 H 13.425 v -32.552 c -0.194,-1.855 0.335,-3.713 1.478,-5.194 0.698,-0.573 1.504,-1.001 2.371,-1.261 0.868,-0.259 1.778,-0.345 2.679,-0.251 H 30.078 V -50.841 H 17.244 c -5.987,0 -10.371,1.39 -13.105,4.17 C 1.404,-43.891 0,-39.648 0,-33.869 Z"></path>
        </g>
        <g transform="translate(170,-60)">
          <path d="m 0,0 h -16.505 c -3.348,0.188 -6.673,-0.664 -9.508,-2.438 -1.1,-0.8 -1.981,-1.859 -2.562,-3.081 -0.58,-1.222 -0.843,-2.569 -0.764,-3.918 -0.035,-1.248 0.233,-2.486 0.782,-3.611 0.549,-1.124 1.362,-2.102 2.372,-2.85 2.495,-1.751 5.518,-2.611 8.572,-2.439 4.704,-0.278 9.328,1.3 12.859,4.389 1.556,1.528 2.78,3.354 3.598,5.366 0.817,2.013 1.211,4.17 1.156,6.339 z m 19.953,-16.337 v -11.485 h -7.02 c -3.183,-0.311 -6.364,0.616 -8.869,2.585 -0.935,0.969 -1.658,2.119 -2.124,3.378 -0.466,1.258 -0.666,2.598 -0.585,3.937 -2.203,-3.451 -5.289,-6.263 -8.945,-8.152 -3.656,-1.888 -7.751,-2.786 -11.87,-2.602 -6.066,-0.309 -12.061,1.409 -17.022,4.877 -2.084,1.556 -3.755,3.589 -4.871,5.925 -1.116,2.336 -1.642,4.905 -1.534,7.486 -0.151,2.837 0.398,5.666 1.6,8.245 1.201,2.579 3.02,4.83 5.298,6.556 4.614,3.463 11.314,5.194 20.1,5.194 H 0 v 3.78 c 0.074,1.56 -0.222,3.115 -0.864,4.542 -0.641,1.427 -1.611,2.685 -2.831,3.675 -2.951,2.149 -6.566,3.21 -10.223,3 -3.362,0.16 -6.693,-0.69 -9.558,-2.439 -1.198,-0.723 -2.227,-1.69 -3.017,-2.838 -0.791,-1.148 -1.324,-2.45 -1.565,-3.819 h -13.055 c 0.205,2.903 1.064,5.723 2.513,8.255 1.449,2.531 3.452,4.71 5.862,6.376 5.746,3.694 12.518,5.51 19.362,5.194 8.441,0 15.01,-1.951 19.707,-5.853 2.348,-2.114 4.187,-4.724 5.381,-7.636 1.194,-2.912 1.712,-6.052 1.516,-9.189 v -24.628 c 0,-2.926 1.38,-4.389 4.188,-4.389 z"></path>
        </g>
        <g transform="translate(111,-50)">
          <path d="m 0,0 c -0.351,4.122 -2.313,7.945 -5.469,10.656 -3.286,2.635 -7.425,4.003 -11.651,3.853 -4.118,0.083 -8.123,-1.331 -11.258,-3.975 C -29.952,9.225 -31.248,7.62 -32.191,5.812 -33.133,4.004 -33.705,2.028 -33.871,0 Z m -44.341,9.924 c 2.442,4.704 6.207,8.608 10.839,11.241 4.865,2.693 10.366,4.056 15.938,3.951 5.585,0.116 11.112,-1.141 16.086,-3.658 4.562,-2.37 8.36,-5.961 10.962,-10.363 2.746,-4.794 4.173,-10.217 4.138,-15.728 -0.003,-1.673 -0.126,-3.343 -0.369,-4.999 h -47.567 v -0.731 c 0.048,-4.794 1.891,-9.398 5.172,-12.924 1.604,-1.608 3.525,-2.872 5.645,-3.711 2.119,-0.839 4.391,-1.236 6.672,-1.165 3.663,-0.128 7.277,0.869 10.346,2.852 2.818,1.916 4.799,4.818 5.543,8.12 h 13.327 c -1.047,-6.163 -4.365,-11.723 -9.312,-15.606 -5.379,-4.186 -12.08,-6.353 -18.918,-6.12 -5.996,-0.143 -11.929,1.224 -17.244,3.975 -4.802,2.542 -8.742,6.425 -11.331,11.168 -2.767,5.133 -4.15,10.886 -4.015,16.703 -0.088,5.914 1.318,11.755 4.088,16.995"></path>
        </g>
        <g transform="translate(10,-77)">
          <path d="m 0,0 h 18.721 c 4.547,-0.295 9.067,0.877 12.884,3.341 1.466,1.072 2.644,2.484 3.43,4.113 0.786,1.628 1.156,3.423 1.078,5.226 0.087,1.851 -0.282,3.695 -1.076,5.373 -0.794,1.679 -1.989,3.14 -3.481,4.258 -3.792,2.484 -8.296,3.683 -12.835,3.414 H 0 Z m 0,37.308 h 17.859 c 4.034,-0.246 8.028,0.919 11.282,3.291 1.326,1.097 2.377,2.482 3.074,4.047 0.696,1.565 1.018,3.268 0.942,4.976 0.078,1.701 -0.244,3.397 -0.94,4.954 -0.697,1.558 -1.75,2.933 -3.076,4.019 -3.271,2.331 -7.258,3.469 -11.282,3.219 L 0,61.814 Z m 17.49,36.21 c 9.459,0 16.775,-1.951 21.997,-5.852 4.236,-3.449 6.978,-8.371 7.665,-13.757 C 47.839,48.523 46.419,43.08 43.183,38.698 40.097,35.221 35.911,32.883 31.309,32.065 44.201,29.871 50.646,23.165 50.646,11.948 50.786,8.592 50.101,5.253 48.651,2.217 47.201,-0.82 45.029,-3.463 42.32,-5.486 36.786,-9.729 28.879,-11.851 18.598,-11.851 h -32.762 v 85.345 z"></path>
        </g>
      </g>
    </svg>
  );
};
