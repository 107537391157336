// PACKAGES
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// INTEGRATIONS
import BeatportAPI from '@integrations/BeatportAPI';
// COMPONENTS
import { HypeLogo } from '@components/HypeLogo/HypeLogo';
// UTILS
import { prettyDate } from '@lib/utils';
// STYLES
import './Enrolled.scss';

/**
 * @type {React.Component}
 * @param {Object} label
 * @description Route displayed when selected label is already enrolled in Hype
 */
export default class Enrolled extends Component {
  constructor (props) {
    super(props);
    this.state = {
      /**
       * @type {Boolean} current loading state
       */
      loading: true,
      /**
       * @type {Object} specific subscription that is already enrolled
       */
      subscription: {},
    };
  }

  async componentDidMount () {
    try {
      const { request } = BeatportAPI.subscriptions.get();
      const response = await request;
      const subscriptions = response.data.results;
      const subscription = subscriptions.filter(sub => sub.label.id === this.props.label.id)[0];
      this.setState({ subscription });
    } catch (err) {
      this.props.throwError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render () {
    const { loading, subscription } = this.state;
    const labelName = this.props.label ? this.props.label.name : 'your label';
    return loading ? null : (
      <div className="Enrolled">
        <HypeLogo className="Enrolled__logo" />
        <div className="Enrolled__message">
          {subscription && subscription.recurly_subscription_status === 'canceled'
            ? `It looks like you recently canceled ${labelName}'s Hype subscription.`
            : `Thanks for your interest, but ${labelName} is already enrolled in Hype.`
          }
        </div>
        {subscription && subscription.end_date && (
          <Fragment>
            <hr />
            <div className="Enrolled__message">
                Your subscription is set to end on {prettyDate(subscription.end_date)}.
            </div>
          </Fragment>
        )}
        <hr />
        <div className="Enrolled__message">
            Check your <Link to={{ pathname: '/account' }}> account settings</Link> to manage your subscriptions.
        </div>
      </div>
    );
  }
}
